import { type ReactElement, useContext, useEffect } from 'react'
import { CartPopoverContext } from './CartPopoverContext'
import { CartEmpty } from './CartEmpty'
import { type CartLine } from './types'
import { CartCard } from './CartCard'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { useCurrency } from '@stuller/shared/util/react-hooks'
import { trackViewCart } from '@stuller/stullercom/feat/google-tag-manager'

interface CartListInnerProps {
  cartLines: CartLine[]
  cartTotal: NonNullable<CartLine['price']>['total'] | null
}

/**
 * The list of cart lines
 */
function CartListInner ({ cartLines, cartTotal }: CartListInnerProps): ReactElement {
  const { showcaseSettings } = useAuth()
  const hideUSDPrices = showcaseSettings?.hideUSDPrices ?? false
  const [subtotal] = useCurrency(cartTotal?.value, cartTotal?.exchangeRate)
  const [subtotalUsd] = useCurrency(cartTotal?.valueInUsd)
  const currencyCode = cartTotal?.exchangeRate.code.toUpperCase() ?? 'USD'
  const showUsdConversionPrice = (currencyCode !== 'USD' && !hideUSDPrices)

  return (
    <div data-gtm-click-section=''>
      {cartTotal?.value != null && (
        <div className='row py-5 px-4'>
          <div className='col-auto fs-4'>Estimated Subtotal</div>
          <div className='col'>
            <div className='text-end fw-bold fs-3' data-test='estimated-total'>{subtotal}</div>
            {showUsdConversionPrice && (
              <div className='text-end align-items-baseline fw-normal fst-italic fs-6 text-gray-600' data-test='international-price'>
                {subtotalUsd} (USD)
              </div>)}
          </div>
        </div>)}
      <div className='overflow-auto scrollbar' style={{ maxHeight: '55vh' }}>
        {cartLines.map((value: CartLine) => <CartCard key={value.id} cartLine={value} />)}
      </div>
    </div>
  )
}

/**
 * The container that renders the list of cart lines
 */
function CartList (): ReactElement {
  const { cart } = useContext(CartPopoverContext)
  const auth = useAuth()
  const cartObject = cart.loading
    ? cart.previousData?.cart
    : cart.data?.cart
  const cartLines = cartObject?.items

  useEffect(() => {
    trackViewCart(cartObject, auth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackViewCart])

  if (cartObject == null || cartLines == null || cartLines?.length === 0) {
    return <CartEmpty />
  }

  return <CartListInner cartLines={cartLines} cartTotal={cartObject.price?.subtotal ?? null} />
}

export {
  CartList
}
