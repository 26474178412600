import { useCurrency } from '@stuller/shared/util/react-hooks'
import type { ReactElement } from 'react'
import type { TotalPrice, DiscountPrice } from './ProductCard'

interface ProductCardPriceProps {
  /**
   * Total Price of an product
   */
  total?: TotalPrice
  /**
   * Maximum price of a product group
   */
  totalMax?: TotalPrice
  /**
   * Discount of a product or product group
   */
  discount?: DiscountPrice | null
}

interface DiscountPriceProps {
  /**
   * if product group has maximum price
   */
  hasTotalMax: boolean
  /**
   * Discount of a product or product group
   */
  discount?: DiscountPrice | null
}

/**
 * Displays the discount of a product or product group
 */
function DiscountedPrice ({
  discount,
  hasTotalMax
}: DiscountPriceProps): ReactElement | null {
  const hasDiscount = discount?.originalPrice != null && discount?.percent != null
  const [discountPrice] = useCurrency(discount?.originalPrice?.value, discount?.originalPrice.exchangeRate)

  if (!hasDiscount) {
    return null
  }

  if (hasTotalMax) {
    return (
      <div className='fs-6 col-12 text-danger fw-bold'>
        Up to {discount?.percent}% Off
      </div>
    )
  }

  return (
    <>
      <span className='text-decoration-line-through text-body-secondary'>
        {discountPrice}
      </span>
      <div className='fs-6 col-12 text-danger fw-bold'>
        {discount?.percent}% Off
      </div>
    </>
  )
}

/**
 * Displays the price of a product or price range of a product group
 */
function ProductCardPrice ({
  total,
  totalMax,
  discount
}: ProductCardPriceProps): ReactElement | null {
  const [totalPrice] = useCurrency(total?.value, total?.exchangeRate)
  const hasTotalMax = totalMax?.value != null && total?.value !== totalMax?.value
  const [totalMaxPrice] = useCurrency(totalMax?.value, totalMax?.exchangeRate)

  if (total?.value == null) {
    return null
  }

  return (
    <div className='d-flex flex-wrap'>
      <div className='fw-bold pe-2'>
        {totalPrice}
        {hasTotalMax && (
          <span>&mdash;{totalMaxPrice}</span>
        )}
      </div>
      <DiscountedPrice discount={discount} hasTotalMax={hasTotalMax} />
    </div>
  )
}

export {
  ProductCardPrice
}
