import { type ApolloError } from '@apollo/client/errors'

/**
 * Get client validation errors from Apollo errors
 */
function getClientValidationErrors (...apolloErrors: Array<ApolloError | null | undefined>): string[] {
  const validationErrors = []
  for (const error of apolloErrors) {
    if (error != null) {
      for (const graphQLError of error.graphQLErrors) {
        if (graphQLError.extensions?.code != null && graphQLError.extensions.code === 'BAD_USER_INPUT') {
          validationErrors.push(graphQLError.message)
        }
      }
    }
  }

  return validationErrors
}

export {
  getClientValidationErrors
}
