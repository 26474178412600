import { type ReactElement } from 'react'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import Link from 'next/link'
import { isStringEmpty } from '@stuller/shared/util/core'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { DirectoryCardGroupDirectoryCardsDirectoryLinks } from './types'

interface ContentstackDirectoryLinkProps {
  data?: DirectoryCardGroupDirectoryCardsDirectoryLinks | null
}

/**
 * Individual links inside of a Directory Card
 */
function ContentstackDirectoryLink ({ data }: ContentstackDirectoryLinkProps): ReactElement | null {
  const {
    link,
    link_text: linkText
  } = data ?? {}

  const isLinkEmpty = isStringEmpty(link?.href)
  const isLinkTextEmpty = isRteEmpty(linkText?.json)

  if (isLinkEmpty || isLinkTextEmpty) {
    return null
  }

  return (
    <div className='row'>
      <div className='col d-flex align-items-center justify-content-between border-bottom py-4'>
        <Link
          href={link.href ?? ''}
          title={link.title ?? ''}
          className='text-decoration-none fs-3 text-body fw-normal text-decoration-underline-hover'
        >
          <ContentstackJsonRte excludeOuterTags json={linkText?.json} />
        </Link>
        <FontAwesomeIcon
          icon={fas.faChevronRight}
        />
      </div>
    </div>
  )
}

export {
  ContentstackDirectoryLink
}
