import type { Targeting } from '@stuller/contentstack/util/types'
import { use302MemberTargeting } from './use302MemberTargeting'
import { useAuthenticatedTargeting } from './useAuthenticatedTargeting'
import { useBillToAccountTargeting } from './useBillToAccountTargeting'
import { useCategoryExcludeTargeting } from './useCategoryExcludeTargeting'
import { useCountryTargeting } from './useCountryTargeting'
import { useEcommerceTargeting } from './useEcommerceTargeting'
import { useEverAndEverMemberTargeting } from './useEverAndEverMemberTargeting'
import { useGroupTargeting } from './useGroupTargeting'
import { useJewelerShowcaseTargeting } from './useJewelerShowcaseTargeting'
import { usePricelistTargeting } from './usePricelistTargeting'
import { useQueryStringTargeting } from './useQueryStringTargeting'
import { useShipToAccountTargeting } from './useShipToAccountTargeting'
import { useShowcaseModeTargeting } from './useShowcaseModeTargeting'
import { useShowcaseSubdomainTargeting } from './useShowcaseSubdomainTargeting'
import { useShowcaseTargeting } from './useShowcaseTargeting'
import { useSterlingTargeting } from './useSterlingTargeting'
import { useStullerFirstEligibleTargeting } from './useStullerFirstEligibleTargeting'
import { useStullerFirstTargeting } from './useStullerFirstTargeting'

const targetHooks = {
  All: useGroupTargeting,
  Any: useGroupTargeting,
  None: useGroupTargeting,
  Authenticated: useAuthenticatedTargeting,
  BillToAccount: useBillToAccountTargeting,
  CategoryExclude: useCategoryExcludeTargeting,
  Country: useCountryTargeting,
  Ecommerce: useEcommerceTargeting,
  EverAndEverMember: useEverAndEverMemberTargeting,
  JewelerShowcase: useJewelerShowcaseTargeting,
  PriceList: usePricelistTargeting,
  QueryString: useQueryStringTargeting,
  ShipToAccount: useShipToAccountTargeting,
  Showcase: useShowcaseTargeting,
  ShowcaseMode: useShowcaseModeTargeting,
  ShowcaseSubdomain: useShowcaseSubdomainTargeting,
  Sterling: useSterlingTargeting,
  StullerFirst: useStullerFirstTargeting,
  StullerFirstEligible: useStullerFirstEligibleTargeting,
  '302Member': use302MemberTargeting
}

/**
 * Checks the targeting of an item by calling the appropriate hook based on the target type
 */
function checkTargeting (targeting: Targeting): boolean {
  switch (targeting?.targetType) {
    case 'ALL':
      return targetHooks.All(targeting.targetValue, 'ALL')
    case 'ANY':
      return targetHooks.Any(targeting.targetValue, 'ANY')
    case 'NONE':
      return targetHooks.None(targeting.targetValue, 'NONE')
    case 'AUTHENTICATED':
      return targetHooks.Authenticated(targeting)
    case 'BILL_TO_ACCOUNT':
      return targetHooks.BillToAccount(targeting)
    case 'CATEGORY_EXCLUDE':
      return targetHooks.CategoryExclude(targeting)
    case 'COUNTRY':
      return targetHooks.Country(targeting)
    case 'ECOMMERCE':
      return targetHooks.Ecommerce(targeting)
    case 'EVER_AND_EVER_MEMBER':
      return targetHooks.EverAndEverMember(targeting)
    case 'JEWELER_SHOWCASE':
      return targetHooks.JewelerShowcase(targeting)
    case 'PRICE_LIST':
      return targetHooks.PriceList(targeting)
    case 'QUERY_STRING':
      return targetHooks.QueryString(targeting)
    case 'SHIP_TO_ACCOUNT':
      return targetHooks.ShipToAccount(targeting)
    case 'SHOWCASE':
      return targetHooks.Showcase(targeting)
    case 'SHOWCASE_MODE':
      return targetHooks.ShowcaseMode(targeting)
    case 'SHOWCASE_SUBDOMAIN':
      return targetHooks.ShowcaseSubdomain(targeting)
    case 'STERLING':
      return targetHooks.Sterling(targeting)
    case 'STULLER_FIRST':
      return targetHooks.StullerFirst(targeting)
    case 'STULLER_FIRST_ELIGIBLE':
      return targetHooks.StullerFirstEligible(targeting)
    case '302_MEMBER':
      return targetHooks['302Member'](targeting)
    // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
    default:
      return true
  }
}

/**
 * Hook to check a specific target based on its target type
 */
function useContentstackTargeting (targeting: any): boolean {
  return checkTargeting(targeting as Targeting)
}

export {
  checkTargeting,
  useContentstackTargeting
}
