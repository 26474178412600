/**
 * Error wrapper around an error that isn't of type `Error` and has no `.toString`
 */
class InvalidError extends Error {
  cause?: unknown

  constructor (message: string, cause?: unknown) {
    super(message)
    this.name = 'InvalidError'
    this.cause = cause

    Object.setPrototypeOf(this, new.target.prototype)
  }
}

/**
 * Rethrows the error for an unknown type
 */
function rethrow (error: unknown): never {
  if (error instanceof Error) {
    throw error
  }

  if (typeof error === 'string') {
    throw new Error(error)
  }

  throw new InvalidError('Unknown Error', error)
}

export {
  InvalidError,
  rethrow
}
