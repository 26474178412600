import { type ReactElement } from 'react'
import { Alert } from '@stuller/stullercom/ui'

/**
 * The error message for the cart popover when something goes wrong
 */
function CartCheckoutError (): ReactElement {
  return (
    <Alert size='sm' color='danger' icon className='mt-3 mb-0'>
      Update or remove the items outlined in red to proceed to checkout.
    </Alert>
  )
}

export {
  CartCheckoutError
}
