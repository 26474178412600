import {
  forwardRef,
  type ReactNode,
  useContext
} from 'react'
import clsx from 'clsx'
import {
  AccordionContext,
  AccordionItemContext,
  type AccordionItemContextType,
  type HeadingTag
} from '../../..'

export interface AccordionHeaderProps extends AccordionItemContextType {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Additional class name(s) to give to the button element
   */
  buttonClassName?: string
  /**
   * Size of the accordion header button
   */
  size?: 'sm'
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * Defined element type
   */
  tag?: HeadingTag | 'div'
}

/**
 * Accordion header component that holds the item header and button.
 */
const AccordionHeader = forwardRef<HTMLDivElement, AccordionHeaderProps>(({
  className,
  buttonClassName,
  size,
  children,
  tag: Tag = 'h4',
  itemId: itemIdIn,
  ...otherAttributes
}, ref) => {
  const { open, onToggle, faqSchema } = useContext(AccordionContext)
  const { itemId: itemIdContext } = useContext(AccordionItemContext)
  const itemId = itemIdIn ?? itemIdContext
  const isOpen = itemId == null || (Array.isArray(open) ? open.includes(itemId) : open === itemId)
  const faqSchemaMicrodata = faqSchema === true && { itemProp: 'name' }
  const classNames = clsx('accordion-header', className)
  const buttonClassNames = clsx(
    'accordion-button',
    !isOpen && 'collapsed',
    size != null && `accordion-button-${size}`,
    buttonClassName
  )

  // Handle toggling the accordion item
  function handleToggle (): void {
    if (itemId != null) {
      onToggle?.(itemId)
    }
  }

  return (
    <Tag
      className={classNames}
      {...faqSchemaMicrodata}
      ref={ref}
      {...otherAttributes}
    >
      <button className={buttonClassNames} onClick={handleToggle} type='button'>
        {children}
      </button>
    </Tag>
  )
})
AccordionHeader.displayName = 'AccordionHeader'

export {
  AccordionHeader
}
