import { useEffect, useMemo } from 'react'
import {
  type IntersectionObserverHookRef,
  type IntersectionObserverOptions,
  type IntersectionObserverHookRootRef as UseInfiniteScrollHookRootRef
} from './useIntersectionObserver'
import { useTrackVisibility } from './useTrackVisibility'

type UseInfiniteScrollOptions = Pick<IntersectionObserverOptions, 'rootMargin'> & {
  /**
   * Indicate that the data is loading
   */
  loading: boolean
  /**
   * Indicate that there are more items to load
   */
  hasMore: boolean
  /**
   * Flag to force stop infinite scrolling (can be used in case of an error etc.)
   */
  disabled?: boolean
  /**
   * The callback function to execute when the 'onLoadMore' is triggered
   */
  onLoadMore: () => void | Promise<void>
  /**
   * How long (in ms) to wait before triggering 'onLoadMore' (helps to not trigger load more twice in a row before loading is `true`)
   */
  delay?: number
}

type UseInfiniteScroll = [
  IntersectionObserverHookRef,
  {
    rootRef: UseInfiniteScrollHookRootRef
  }
]

/**
 * Hook that wraps `useTrackVisibility` to help call a handler when an element is visible and we want to load more items (infinite scrolling)
 * See https://github.com/onderonur/react-infinite-scroll-hook
 */
function useInfiniteScroll ({
  loading,
  hasMore,
  disabled = false,
  onLoadMore,
  delay = 100,
  rootMargin
}: UseInfiniteScrollOptions): UseInfiniteScroll {
  const [ref, { rootRef, visible }] = useTrackVisibility({ rootMargin })
  const loadMore = useMemo(() => !disabled && !loading && visible && hasMore, [disabled, loading, visible, hasMore])

  useEffect(() => {
    if (loadMore) {
      // When we trigger 'onLoadMore' and new items are added, before they become rendered on the screen 'loading' can be false and 'isVisible' can be true.
      // We use a small delay here to prevent load more from being triggered twice in a row.
      const timer = setTimeout(() => {
        void onLoadMore()
      }, delay)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [onLoadMore, loadMore, delay])

  return [ref, { rootRef }]
}

export {
  useInfiniteScroll
}
