import { forwardRef } from 'react'
import { type IconProp } from '@fortawesome/fontawesome-svg-core'
import { Alert, type AlertProps } from '../../..'

export interface ToastProps extends Pick<AlertProps, 'id' | 'className' | 'style' | 'children' | 'color'> {
  /**
   * Ignore, sent from `react-toastify`
   */
  toastProps?: any
  /**
   * Ignore, sent from `react-toastify`
   */
  closeToast?: any
  /**
   * Optional icon to display to the left of the alert
   * Send `true` to show the default icon
   */
  icon?: IconProp | null | boolean
}

/**
 * Provide toast messages for user actions and async updates.
 *
 * See `useToast` to use a toast.
 */
const Toast = forwardRef<HTMLDivElement, ToastProps>(({
  children,
  icon = true,
  toastProps,
  closeToast,
  ...otherAttributes
}, ref) => {
  return (
    <Alert
      transparent
      icon={icon}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </Alert>
  )
})
Toast.displayName = 'Toast'

export {
  Toast
}
