import { type ReactElement } from 'react'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import { useContentstackBackground } from '../contentstack-background/useContentstackBackground'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import clsx from 'clsx'
import Link from 'next/link'
import variables from '@stuller/stullercom/ui/styles/variables.module.scss'
import type css from 'styled-jsx/css'
import type { ExpandableImageGroupExpandableImages } from './types'

interface ContentstackExpandableImageProps {
  data?: ExpandableImageGroupExpandableImages | null
  spacing?: ReturnType<typeof css.resolve> | null
  groupTitle?: string | null
}

/**
 * Individual Expandable Images with hover text
 */
function ContentstackExpandableImage ({ data, spacing, groupTitle }: ContentstackExpandableImageProps): ReactElement | null {
  const {
    title,
    appearance,
    header,
    targetingConnection,
    link
  } = data ?? {}

  const background = useContentstackBackground(null, appearance?.background_image)
  const hasHoverText = !isRteEmpty(header?.hover_text?.json)
  const hasHeadingText = !isRteEmpty(header?.heading?.json)
  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent || !hasHeadingText) {
    return null
  }

  const expandableImageClassNames = clsx(
    background.className,
    spacing?.className,
    appearance?.text_color !== 'body' && 'text-white',
    'expandable-image col-12 col-lg-3 overflow-hidden position-relative d-flex flex-column flex-wrap justify-content-end align-items-center'
  )
  const linkClassNames = clsx(
    'stretched-link text-decoration-none col-12 d-flex justify-content-center',
    appearance?.text_color !== 'body' && 'text-white'
  )
  const headingClassNames = clsx(
    appearance?.heading_font_size ?? ''
  )

  return (
    <div
      className={expandableImageClassNames}
      data-gtm-click-section='bucket'
      data-gtm-click-subsection1={groupTitle}
    >
      <Link href={link?.href ?? ''} title={link?.title ?? ''} className={linkClassNames} data-gtm-click-text={title}>
        <div className='py-6 w-100 z-1 position-relative expandable-image-blur bg-glass-blur bg-glass-dark row text-center'>
          <h2 className={headingClassNames}><ContentstackJsonRte excludeOuterTags json={header?.heading?.json} /></h2>
          {hasHoverText && (
            <div className='hover-text'>
              <h3 className='mb-n3'><ContentstackJsonRte excludeOuterTags json={header?.hover_text?.json} /></h3>
            </div>
          )}
        </div>
      </Link>
      <style jsx>{`
          @media (min-width: ${variables.breakpointLg}) {
            .hover-text {
              margin-bottom:-3rem;
              opacity: 0;
              transition: .25s ease-in-out;
              transform: translateY(1rem);
            }
            .expandable-image {
              &:hover {
                width: 40%;
                .hover-text {
                  margin-bottom: 0;
                  opacity: 1;
                  transform: translate(0,0);
                }
              }
            }
          }

          .expandable-image {
            min-height: 600px;
            transition: .25s ease-in-out;
            @media (max-width: ${variables.breakpointLg}) {
              min-height: 300px;
            }
          }
        `}
      </style>
      {background.styles}
      {spacing?.styles}
    </div>
  )
}

export {
  ContentstackExpandableImage
}
