import { createContext } from 'react'
import {
  type GetCartHeaderQueryHookResult,
  type GetCartItemCountQueryHookResult,
  type CancelScheduledOrderMutationHookResult
} from '@stuller/stullercom/data-access/apollo-queries'

export interface CartPopoverContextType {
  /**
   * Result of the cart query
   */
  cart: GetCartHeaderQueryHookResult
  /**
   * Result of the cart item count query
   */
  cartItemCount: GetCartItemCountQueryHookResult
  /**
   * Mutation function to cancel the scheduled order
   */
  cancelScheduledOrder: CancelScheduledOrderMutationHookResult[0]
  /**
   * Result of the cancel scheduled order mutation
   */
  scheduledOrderCancel: CancelScheduledOrderMutationHookResult[1]
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const CartPopoverContext = createContext<CartPopoverContextType>({} as CartPopoverContextType)

export {
  CartPopoverContext
}
