import { useContext, useId, forwardRef } from 'react'
import clsx from 'clsx'
import Select, { type Props, type SelectInstance } from 'react-select'
import { type AsyncFunction } from '@stuller/shared/util/type-helpers'
import { selectDropdownComponents, FormGroupContext } from '../../../..'

export interface SelectDropdownProps extends Omit<Props<any, any, any>, 'isDisabled' | 'onChange' | 'onInputChange'> {
  /**
   * Id of the input element within the select component
   */
  id?: string
  /**
   * Id of the top level select element
   */
  selectId?: string
  /**
   * Indicates to render the select dropdown as a pill
   */
  pill?: boolean
  /**
   * Size of the select dropdown
   */
  size?: 'sm' | 'lg'
  /**
   * Indicates the select dropdown is invalid to show fail style
   */
  invalid?: boolean | string
  /**
   * Indicates the select dropdown is valid to show success style
   */
  valid?: boolean | string
  /**
   * Indicates the select dropdown is disabled (replace `isDisabled` prop)
   */
  disabled?: Props['isDisabled']
  /**
   * Shorcut to hide the dropdown indicator
   */
  hideDropdownIndicator?: boolean
  /**
   * On change handler with async
   */
  onChange?: AsyncFunction<NonNullable<Props<any, any, any>['onChange']>>
  /**
   * On input change handler with async
   */
  onInputChange?: AsyncFunction<NonNullable<Props<any, any, any>['onInputChange']>>
  /**
   * Data test attribute to set on the container
   */
  'data-test'?: string
  /**
   * Makes the input readonly
   */
  readOnly?: boolean
  /**
   * If there are more options to fetch
   */
  hasMoreOptions?: boolean
  /**
   * If more options are loading
   */
  isLoadingMoreResults?: boolean
}

/**
 * Select dropdown component for rendering fancier selects that wraps the [react-select](https://react-select.com/home) `Select` component.
 *
 * All imports should flow through this library, instead of 'react-select' directly, to allow for overriding components, etc.
 * See the Components story for more info.
 */
const SelectDropdown = forwardRef<SelectInstance, SelectDropdownProps>(({
  id: idIn,
  selectId,
  className,
  pill = false,
  size,
  invalid = false,
  valid = false,
  disabled = false,
  placeholder = 'Select...',
  menuPlacement = 'bottom',
  hideDropdownIndicator = false,
  components,
  hasMoreOptions = false,
  isLoadingMoreResults = false,
  ...otherAttributes
}, ref) => {
  const { groupId } = useContext(FormGroupContext)
  const id = idIn ?? groupId
  const instanceId = useId()
  const classNames = clsx(
    'form-select-dropdown',
    pill && 'rounded-pill',
    size != null && `form-select-dropdown-${size}`,
    invalid !== false && invalid !== '' && 'is-invalid',
    valid === true && 'is-valid',
    disabled && 'disabled',
    hasMoreOptions && 'has-more-options',
    className
  )

  return (
    <Select
      inputId={id}
      id={selectId}
      isDisabled={disabled}
      placeholder={placeholder}
      menuPlacement={menuPlacement}
      components={{
        SelectContainer: selectDropdownComponents.SelectContainer,
        Input: selectDropdownComponents.Input,
        DropdownIndicator: hideDropdownIndicator ? undefined : selectDropdownComponents.DropdownIndicator,
        LoadingIndicator: selectDropdownComponents.SelectLoadingIndicator,
        ...components
      }}
      className={classNames}
      classNamePrefix='form-select-dropdown'
      unstyled
      instanceId={instanceId}
      ref={ref}
      isLoading={isLoadingMoreResults}
      {...otherAttributes}
    />
  )
})
SelectDropdown.displayName = 'SelectDropdown'

export {
  SelectDropdown
}
