import { useRef, useEffect, type RefObject } from 'react'

/**
 * Creates an event listener and handles updating and removing it
 * Defaults to listening on `window`, send an `element` ref to prevent bubbling to other components with the same listener
 */
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters
function useEventListener<E extends Event, T extends HTMLElement | Document = HTMLDivElement> (
  eventName: string,
  handler: (event: E) => void,
  element?: RefObject<T>
): void {
  // Create a ref that stores handler
  const savedHandler = useRef<(event: E) => void>()

  // Update savedHandler ref on changes to use latest without needing to pass to `useEffect` deps
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    // Define the listening target
    const targetElement: T | Window = element?.current != null ? element.current : window
    if (targetElement?.addEventListener == null) {
      return
    }

    // Create listener that calls handler function stored in ref
    const listener = (event: E): void => {
      if (savedHandler?.current != null) {
        savedHandler.current(event)
      }
    }

    targetElement.addEventListener(eventName, listener as EventListener)

    // Remove listener on cleanup
    return () => {
      targetElement.removeEventListener(eventName, listener as EventListener)
    }
  }, [eventName, element])
}

export {
  useEventListener
}
