import {
  type ReactNode,
  forwardRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  useCallback,
  type MutableRefObject,
  type CSSProperties
} from 'react'
import clsx from 'clsx'
import { useEventListener } from '@stuller/shared/util/react-hooks'
import { createPortal } from 'react-dom'
import { siteConfig } from '@stuller/stullercom/util/site-config'

const BootstrapModalImport = typeof window !== 'undefined' && (siteConfig.NODE_ENV === 'test' ? require('bootstrap/js/dist/modal') : import('bootstrap/js/dist/modal'))
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Data = require('bootstrap/js/dist/dom/data')

export interface ModalProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Inline styles to pass to the containing element
   */
  style?: CSSProperties
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * Indicates the modal is open
   */
  isOpen?: boolean
  /**
   * Handle called when modal is toggled
   */
  onToggle?: (event: Event) => void
  /**
   * Size of modal non-default
   */
  size?: 'sm' | 'lg' | 'xl'
  /**
   * Fullscreen modal
   */
  fullscreen?: true | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl'
  /**
   * Indicates if modal should be centered vertically
   */
  centered?: boolean
  /**
   * Indicates if modal should be scrollable instead of stretching to fit content
   */
  scrollable?: boolean
  /**
   * Includes a modal-backdrop element, use 'static' for a backdrop which doesn't close the modal on click
   */
  backdrop?: boolean | 'static'
  /**
   * Closes the modal when escape key is pressed
   */
  keyboard?: boolean
  /**
   * Puts the focus on the modal when initialized
   */
  focus?: boolean
  /**
   * Ref to render the modal to
   */
  portalRef?: MutableRefObject<Element | DocumentFragment | undefined>
  /**
   * Handler called when modal is opening
   */
  onShow?: (event: Event) => void
  /**
   * Handler called once the modal is shown
   */
  onShown?: (event: Event) => void
  /**
   * Handler called when the modal is closing
   */
  onHide?: (event: Event) => void
  /**
   * Handler called once the modal is hidden
   */
  onHidden?: (event: Event) => void
  /**
   * Handler called when `static` backdrop and the modal has refused to close
   */
  onHidePrevented?: (event: Event) => void
}

/**
 * Modal component to add dialogs for lightboxes, user notifications, or custom content.
 *
 * For easy modal state management use `useToggle`.
 *
 * By default modals are centered horizontally.
 * Only 1 modal can be open at a time, nested modals are not supported.
 *
 * **Note**: since Storybook uses iFrames on docs, each story uses a portal to render the modal to the parent window, that mostly likely isn't needed in most situations.
 */
const Modal = forwardRef<HTMLDivElement, ModalProps>(({
  className,
  children,
  isOpen = false,
  onToggle,
  size,
  fullscreen,
  centered = false,
  scrollable = false,
  backdrop = true,
  keyboard = true,
  focus = true,
  portalRef,
  onShow = () => {},
  onShown = () => {},
  onHide = () => {},
  onHidden = () => {},
  onHidePrevented = () => {},
  ...otherAttributes
}, ref) => {
  const innerRef = useRef<HTMLDivElement>(null)
  // Expose innerRef, must use non-null assertion because signature requires it and conditional is not allowed
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useImperativeHandle(ref, () => innerRef.current!, [])
  const [modal, setModal] = useState<any>()
  const classNames = clsx('modal', className)
  const dialogClassNames = clsx(
    'modal-dialog',
    size != null && `modal-${size}`,
    centered && 'modal-dialog-centered',
    scrollable && 'modal-dialog-scrollable',
    fullscreen != null && (fullscreen === true ? 'modal-fullscreen' : `modal-fullscreen-${fullscreen}-down`)
  )

  // Handle hide to toggle when closing
  const handleHide = useCallback((event: Event) => {
    if (isOpen) {
      onToggle?.(event)
    }
    onHide(event)
  }, [isOpen, onToggle, onHide])

  // Assign event listeners
  useEventListener('show.bs.modal', onShow, innerRef)
  useEventListener('shown.bs.modal', onShown, innerRef)
  useEventListener('hide.bs.modal', handleHide, innerRef)
  useEventListener('hidden.bs.modal', onHidden, innerRef)
  useEventListener('hidePrevented.bs.modal', onHidePrevented, innerRef)

  // Init modal
  // TODO: remove once SSR is safe https://github.com/twbs/bootstrap/pull/34989
  useEffect(() => {
    if (innerRef?.current != null && modal == null && BootstrapModalImport !== false) {
      if (siteConfig.NODE_ENV !== 'test') {
        BootstrapModalImport?.then((bootstrapModal: any) => {
          const BootstrapModal = bootstrapModal.default
          setModal(new BootstrapModal(innerRef.current as Element, { backdrop, keyboard, focus }))
        })
      } else {
        setModal(new BootstrapModalImport(innerRef.current as Element, { backdrop, keyboard, focus }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerRef.current, portalRef?.current, modal, backdrop, keyboard, focus])

  // Dispose modal
  useEffect(() => {
    return () => {
      modal?.hide?.()
      modal?.dispose?.()
    }
  }, [modal])

  // Toggle modal
  useEffect(() => {
    if (modal != null) {
      if (isOpen) {
        // Close all other modals that are currently shown
        for (const modalShown of document.querySelectorAll('.modal.show')) {
          Data.get(modalShown, 'bs.modal')?.hide()
        }
        modal.show()
      } else {
        modal.hide()
      }
    }
  }, [modal, isOpen])

  // The modal to be rendered (in portal or not)
  const modalInner = (
    <div
      className={classNames}
      tabIndex={-1}
      ref={innerRef}
      {...otherAttributes}
    >
      <div className={dialogClassNames}>
        <div className='modal-content'>
          {isOpen && children}
        </div>
      </div>
    </div>
  )

  if (portalRef?.current != null) {
    return createPortal(modalInner, portalRef.current)
  }

  return modalInner
})
Modal.displayName = 'Modal'

export {
  Modal
}
