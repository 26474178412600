import { type ReactElement } from 'react'
import clsx from 'clsx'
import { EligibleProductsModal } from './EligibleProductsModal'
import type plugin from 'dayjs/plugin/utc'

interface CountdownInfoSectionNoOrderProps {
  /**
   * The dayjs duration for the countdown
   */
  duration: plugin.Duration
  /**
   * Indicates to use portal ref to attach the modal to the body or not
   */
  portalBody: boolean
}

/**
 * A component to show the shipping countdown info section for the "NO_ORDER" kind
 */
function CountdownInfoSectionNoOrder ({ duration, portalBody }: CountdownInfoSectionNoOrderProps): ReactElement {
  const classNames = clsx('fw-bold', duration.asHours() > 4 ? 'text-primary' : 'text-danger')

  return (
    <div>
      <div>
        Eligible items in your cart can ship
        <span className='fw-bold'> today </span>
        if you checkout in the next
        <span className={classNames}>{duration.format(' H[h] m[m] s[s]')}</span>
      </div>
      <EligibleProductsModal portalBody={portalBody} />
    </div>
  )
}

export {
  CountdownInfoSectionNoOrder
}
