import { components } from 'react-select'
import { SelectContainer } from './SelectContainer'
import { Input } from './SelectInput'
import { SelectLoadingIndicator } from './SelectLoadingIndicator'

/**
 * Select components with overrides and alternatives
 */
const selectDropdownComponents = {
  ...components,
  SelectContainer,
  Input,
  SelectLoadingIndicator
}

export {
  selectDropdownComponents
}
