import { type ReactElement } from 'react'
import { type CardGroupCardMedia } from './types'
import { ContentstackSimpleImage, isSimpleImageEmpty } from '../contentstack-simple-image/ContentstackSimpleImage'
import { ContentstackIcon } from '../contentstack-icon/ContentstackIcon'
import { isStringEmpty } from '@stuller/shared/util/core'
import { type SizeProp } from '@fortawesome/fontawesome-svg-core'

interface ContentstackCardMediaProps {
  data: CardGroupCardMedia
}

/**
 * Renders video, full image, portrait image, or icons in a ContentstackCard
 * @memberof ContentstackCardGroup
 */
function ContentstackCardMedia ({ data }: ContentstackCardMediaProps): ReactElement | null {
  const {
    full_image: fullImage,
    portrait_image: portraitImage,
    icon,
    icon_size: iconSize
  } = data ?? {}

  if (isSimpleImageEmpty(fullImage) && isSimpleImageEmpty(portraitImage) && isStringEmpty(icon)) {
    return null
  }

  if (!isSimpleImageEmpty(fullImage)) {
    return (
      <div className='col-12'>
        <div
          style={{
            height: '279px'
          }}
        >
          <ContentstackSimpleImage
            className='img-fluid rounded-top-3 object-fit-cover h-100 w-100'
            data={fullImage}
          />
        </div>
      </div>
    )
  }
  if (!isSimpleImageEmpty(portraitImage)) {
    return (
      <div className='col-12 d-flex justify-content-center pt-5'>
        <div
          style={{
            height: '150px',
            width: '150px',
            minWidth: '150px'
          }}
        >
          <ContentstackSimpleImage
            className='rounded-circle object-fit-cover h-100 w-100'
            data={portraitImage}
          />
        </div>
      </div>
    )
  }
  if (!isStringEmpty(icon)) {
    const size = (iconSize as SizeProp) ?? '4x'

    return (
      <div className='col-12 d-flex justify-content-center pt-5'>
        <ContentstackIcon
          icon={icon}
          size={size}
        />
      </div>
    )
  }

  return null
}

export {
  ContentstackCardMedia
}
