import { type ReactElement, useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, AlertHeading, AlertIcon, Button } from '@stuller/stullercom/ui'
import { MenuCartContext } from './MenuCartContext'
import { CartPopoverContext } from './CartPopoverContext'
import { logger } from '@stuller/stullercom/feat/datadog-logs'

/**
 * Message about the scheduled order
 */
function ScheduledOrderMessage (): ReactElement {
  const { icon } = useContext(MenuCartContext)

  return (
    <Alert color='primary' transparent className='mb-4 py-0'>
      <div className='row align-items-center justify-content-center flex-nowrap'>
        <div className='col-auto'>
          <AlertIcon size='lg'>
            <FontAwesomeIcon icon={icon} />
          </AlertIcon>
        </div>
        <div className='col-auto flex-shrink-1'>
          <AlertHeading tag='h3' className='mb-0' data-test='order-scheduled-message'>
            Your order is scheduled
          </AlertHeading>
          All items in your cart at 5:00 CDT today will be automatically ordered.<br /><br />
          You can continue to add or remove items from your cart.
        </div>
      </div>
    </Alert>
  )
}

/**
 * Button to cancel a scheduled order
 */
function ScheduledOrderCancelButton (): ReactElement {
  const {
    cancelScheduledOrder,
    scheduledOrderCancel,
    cartItemCount,
    cart
  } = useContext(CartPopoverContext)

  /**
   * Cancel scheduled order, log to console if any errors
   */
  async function handleCancelScheduledOrder (): Promise<void> {
    try {
      await cancelScheduledOrder()
    } catch (error) {
      logger.error('handleCancelScheduledOrder() failed.', {}, error)
    }
  }

  return (
    <Button
      color='danger'
      outline
      pill
      className='w-100 mb-4'
      disabled={scheduledOrderCancel.loading || cartItemCount.loading || cart.loading}
      onClick={handleCancelScheduledOrder}
      data-test='turn-off-scheduled-order-button'
    >
      Turn Off Scheduled Order
      <div className='fs-6 fw-normal lh-1'>
        And Keep Items In Cart
      </div>
    </Button>
  )
}

/**
 * Shows the scheduled order information in the cart
 */
function CartScheduledOrder (): ReactElement {
  return (
    <div className='px-6'>
      <ScheduledOrderMessage />
      <ScheduledOrderCancelButton />
    </div>
  )
}

export {
  CartScheduledOrder
}
