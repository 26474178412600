import { type ReactElement, useState } from 'react'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { useAuth } from '@stuller/stullercom/feat/auth'
import { MenuItem } from '../MenuItem'
import { ShowcaseBadge } from './ShowcaseBadge'
import { ShowcasePopover } from './ShowcasePopover'

/**
 * Menu item and dropdown for showcase
 */
function MenuShowcase (): ReactElement {
  const { isShowcase } = useAuth()
  const [open, handleToggle, handleOpenChange] = useToggle()
  const [reference, setReference] = useState<Element | null | undefined>(null)

  return (
    <>
      <MenuItem
        icon={fas.faDesktop}
        text='Showcase'
        open={open}
        onClick={handleToggle}
        setReference={setReference}
      >
        {!isShowcase && <ShowcaseBadge />}
      </MenuItem>
      <ShowcasePopover
        open={open}
        onOpenChange={handleOpenChange}
        reference={reference}
      />
    </>
  )
}

export {
  MenuShowcase
}
