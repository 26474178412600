interface SiteConfig {
  NODE_ENV: string | undefined
  NEXT_PUBLIC_ALGONOMY_BASE_URL: string | undefined
  NEXT_PUBLIC_DATADOG_ENVIRONMENT: string | undefined
  DATADOG_LOGS_SERVER_API_KEY: string | undefined
  NEXT_PUBLIC_DATADOG_LOGS_CLIENT_TOKEN: string | undefined
  NEXT_PUBLIC_DATADOG_LOGS_SESSION_SAMPLE_RATE: number
  NEXT_PUBLIC_CREDIT_CARD_TOKENIZE_HOST: string | undefined
  NEXT_PUBLIC_CONTENTSTACK_GRAPHQL_URL: string | undefined
  NEXT_PUBLIC_CONTENTSTACK_LIVE_PREVIEW_GRAPHQL_URL: string | undefined
  NEXT_PUBLIC_CONTENTSTACK_PREVIEW_TOKEN: string | undefined
  NEXT_PUBLIC_CONTENTSTACK_DELIVERY_TOKEN: string | undefined
  NEXT_PUBLIC_CONTENTSTACK_BRANCH: string | undefined
  NEXT_PUBLIC_JEWELER_SHOWCASE_PUNCHOUT_HOST_MASK: string | undefined
  NEXT_PUBLIC_JEWELER_SHOWCASE_HOST_MASK: string | undefined
  NEXT_PUBLIC_STERLING_HOST_MASK: string | undefined
  NEXT_PUBLIC_ENVIRONMENT_ALERT: string | undefined
  SALESFORCE_ENVIRONMENT: string | undefined
  NEXT_PUBLIC_DATADOG_RUM_SESSION_SAMPLE_RATE: number
  NEXT_PUBLIC_HOST_OVERRIDE: string | undefined
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID: string | undefined
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_PARAMS: string | undefined
  NEXT_PUBLIC_GRAPHQL_REF: string | undefined
  NEXT_PUBLIC_STULLERCOM_EXAMPLE_URL: string | undefined
  NEXT_PUBLIC_STULLERCOM_EXAMPLE_SITEMODE_HEADER: string | undefined
  NEXT_PUBLIC_STERLING_EXAMPLE_URL: string | undefined
  NEXT_PUBLIC_STERLING_EXAMPLE_SITEMODE_HEADER: string | undefined
  NEXT_PUBLIC_JEWELER_SHOWCASE_EXAMPLE_URL: string | undefined
  NEXT_PUBLIC_JEWELER_SHOWCASE_EXAMPLE_SITEMODE_HEADER: string | undefined
  NEXT_PUBLIC_JEWELER_SHOWCASE_IFRAME_EXAMPLE_URL: string | undefined
  NEXT_PUBLIC_JEWELER_SHOWCASE_IFRAME_EXAMPLE_SITEMODE_HEADER: string | undefined
  NEXT_PUBLIC_JEWELER_SHOWCASE_IFRAME_CATEGORY_EMBED_EXAMPLE_URL: string | undefined
  NEXT_PUBLIC_JEWELER_SHOWCASE_IFRAME_CATEGORY_EMBED_EXAMPLE_SITEMODE_HEADER: string | undefined
  NEXT_PUBLIC_JEWELER_SHOWCASE_IFRAME_CATEGORY_EMBED_WITH_CAT_ID_EXAMPLE_URL: string | undefined
  NEXT_PUBLIC_JEWELER_SHOWCASE_IFRAME_CATEGORY_EMBED_WITH_CAT_ID_EXAMPLE_SITEMODE_HEADER: string | undefined
  NEXT_PUBLIC_JEWELER_SHOWCASE_PUNCHOUT_EXAMPLE_URL: string | undefined
  NEXT_PUBLIC_JEWELER_SHOWCASE_PUNCHOUT_EXAMPLE_SITEMODE_HEADER: string | undefined
  NEXT_PUBLIC_JEWELER_SHOWCASE_HOST: string | undefined
  NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: string | undefined
}
export const siteConfig: SiteConfig = {
  NODE_ENV: process.env.NODE_ENV,
  NEXT_PUBLIC_ALGONOMY_BASE_URL: process.env.NEXT_PUBLIC_ALGONOMY_BASE_URL,
  NEXT_PUBLIC_DATADOG_ENVIRONMENT: process.env.NEXT_PUBLIC_DATADOG_ENVIRONMENT,
  DATADOG_LOGS_SERVER_API_KEY: process.env.DATADOG_LOGS_SERVER_API_KEY,
  NEXT_PUBLIC_DATADOG_LOGS_CLIENT_TOKEN: process.env.NEXT_PUBLIC_DATADOG_LOGS_CLIENT_TOKEN,
  NEXT_PUBLIC_DATADOG_LOGS_SESSION_SAMPLE_RATE: Number(process.env.NEXT_PUBLIC_DATADOG_LOGS_SESSION_SAMPLE_RATE),
  NEXT_PUBLIC_CREDIT_CARD_TOKENIZE_HOST: process.env.NEXT_PUBLIC_CREDIT_CARD_TOKENIZE_HOST,
  NEXT_PUBLIC_CONTENTSTACK_GRAPHQL_URL: process.env.NEXT_PUBLIC_CONTENTSTACK_GRAPHQL_URL,
  NEXT_PUBLIC_CONTENTSTACK_LIVE_PREVIEW_GRAPHQL_URL: process.env.NEXT_PUBLIC_CONTENTSTACK_LIVE_PREVIEW_GRAPHQL_URL,
  NEXT_PUBLIC_CONTENTSTACK_PREVIEW_TOKEN: process.env.NEXT_PUBLIC_CONTENTSTACK_PREVIEW_TOKEN,
  NEXT_PUBLIC_CONTENTSTACK_DELIVERY_TOKEN: process.env.NEXT_PUBLIC_CONTENTSTACK_DELIVERY_TOKEN,
  NEXT_PUBLIC_CONTENTSTACK_BRANCH: process.env.NEXT_PUBLIC_CONTENTSTACK_BRANCH,
  NEXT_PUBLIC_JEWELER_SHOWCASE_PUNCHOUT_HOST_MASK: process.env.NEXT_PUBLIC_JEWELER_SHOWCASE_PUNCHOUT_HOST_MASK,
  NEXT_PUBLIC_JEWELER_SHOWCASE_HOST_MASK: process.env.NEXT_PUBLIC_JEWELER_SHOWCASE_HOST_MASK,
  NEXT_PUBLIC_STERLING_HOST_MASK: process.env.NEXT_PUBLIC_STERLING_HOST_MASK,
  NEXT_PUBLIC_ENVIRONMENT_ALERT: process.env.NEXT_PUBLIC_ENVIRONMENT_ALERT,
  SALESFORCE_ENVIRONMENT: process.env.SALESFORCE_ENVIRONMENT,
  NEXT_PUBLIC_DATADOG_RUM_SESSION_SAMPLE_RATE: Number(process.env.NEXT_PUBLIC_DATADOG_RUM_SESSION_SAMPLE_RATE),
  NEXT_PUBLIC_HOST_OVERRIDE: process.env.NEXT_PUBLIC_HOST_OVERRIDE,
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID,
  NEXT_PUBLIC_GOOGLE_TAG_MANAGER_PARAMS: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_PARAMS,
  NEXT_PUBLIC_GRAPHQL_REF: process.env.NEXT_PUBLIC_GRAPHQL_REF,
  NEXT_PUBLIC_STULLERCOM_EXAMPLE_URL: process.env.NEXT_PUBLIC_STULLERCOM_EXAMPLE_URL,
  NEXT_PUBLIC_STULLERCOM_EXAMPLE_SITEMODE_HEADER: process.env.NEXT_PUBLIC_STULLERCOM_EXAMPLE_SITEMODE_HEADER,
  NEXT_PUBLIC_STERLING_EXAMPLE_URL: process.env.NEXT_PUBLIC_STERLING_EXAMPLE_URL,
  NEXT_PUBLIC_STERLING_EXAMPLE_SITEMODE_HEADER: process.env.NEXT_PUBLIC_STERLING_EXAMPLE_SITEMODE_HEADER,
  NEXT_PUBLIC_JEWELER_SHOWCASE_EXAMPLE_URL: process.env.NEXT_PUBLIC_JEWELER_SHOWCASE_EXAMPLE_URL,
  NEXT_PUBLIC_JEWELER_SHOWCASE_EXAMPLE_SITEMODE_HEADER: process.env.NEXT_PUBLIC_JEWELER_SHOWCASE_EXAMPLE_SITEMODE_HEADER,
  NEXT_PUBLIC_JEWELER_SHOWCASE_IFRAME_EXAMPLE_URL: process.env.NEXT_PUBLIC_JEWELER_SHOWCASE_IFRAME_EXAMPLE_URL,
  NEXT_PUBLIC_JEWELER_SHOWCASE_IFRAME_EXAMPLE_SITEMODE_HEADER: process.env.NEXT_PUBLIC_JEWELER_SHOWCASE_IFRAME_EXAMPLE_SITEMODE_HEADER,
  NEXT_PUBLIC_JEWELER_SHOWCASE_IFRAME_CATEGORY_EMBED_EXAMPLE_URL: process.env.NEXT_PUBLIC_JEWELER_SHOWCASE_IFRAME_CATEGORY_EMBED_EXAMPLE_URL,
  NEXT_PUBLIC_JEWELER_SHOWCASE_IFRAME_CATEGORY_EMBED_EXAMPLE_SITEMODE_HEADER: process.env.NEXT_PUBLIC_JEWELER_SHOWCASE_IFRAME_CATEGORY_EMBED_EXAMPLE_SITEMODE_HEADER,
  NEXT_PUBLIC_JEWELER_SHOWCASE_IFRAME_CATEGORY_EMBED_WITH_CAT_ID_EXAMPLE_URL: process.env.NEXT_PUBLIC_JEWELER_SHOWCASE_IFRAME_CATEGORY_EMBED_WITH_CAT_ID_EXAMPLE_URL,
  NEXT_PUBLIC_JEWELER_SHOWCASE_IFRAME_CATEGORY_EMBED_WITH_CAT_ID_EXAMPLE_SITEMODE_HEADER: process.env.NEXT_PUBLIC_JEWELER_SHOWCASE_IFRAME_CATEGORY_EMBED_WITH_CAT_ID_EXAMPLE_SITEMODE_HEADER,
  NEXT_PUBLIC_JEWELER_SHOWCASE_PUNCHOUT_EXAMPLE_URL: process.env.NEXT_PUBLIC_JEWELER_SHOWCASE_PUNCHOUT_EXAMPLE_URL,
  NEXT_PUBLIC_JEWELER_SHOWCASE_PUNCHOUT_EXAMPLE_SITEMODE_HEADER: process.env.NEXT_PUBLIC_JEWELER_SHOWCASE_PUNCHOUT_EXAMPLE_SITEMODE_HEADER,
  NEXT_PUBLIC_JEWELER_SHOWCASE_HOST: process.env.NEXT_PUBLIC_JEWELER_SHOWCASE_HOST,
  NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY
}
