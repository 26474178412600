import { type ReactElement } from 'react'
import Link from 'next/link'
import { Title } from '@stuller/stullercom/feat/layout'
import { Breadcrumb, BreadcrumbItem, Button } from '@stuller/stullercom/ui'
import { useRouter } from 'next/router'
import {
  ProductRecommendationCarousel,
  type RecommendationPlacementProps
} from '@stuller/stullercom/feat/recommendations'
import { SearchBySerialNumber } from '@stuller/stullercom/feat/serialized-pdp-pages'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { dispatchStullerEvent } from '@stuller/stullercom/feat/stuller-events'

const PLACEMENTS: RecommendationPlacementProps[] = [
  {
    recommendationSource: 'cms-rec-item_page.not_available_recs',
    placementId: 'search_page.noresults_serialized'
  }
]
const numbersOnlyRegex = /^\d+$/

/**
 * 404 error page (no layout)
 */
function ErrorSerialized404 (): ReactElement {
  const { query } = useRouter()
  const { slug } = query

  // make sure the serial number is actually a number
  const serialNumber = slug?.[0].match(numbersOnlyRegex)?.[0]

  function handleGoBack (): void {
    window.history.back()
  }

  function handleOpenLiveChat (): void {
    dispatchStullerEvent('live-chat-open')
  }

  return (
    <div className='container-xxxl mw-xxl px-4'>
      <Title>Serial Number Not Found</Title>
      <div>
        <Breadcrumb>
          <BreadcrumbItem href='/'>
            Home
          </BreadcrumbItem>
          <BreadcrumbItem active>
            Products
          </BreadcrumbItem>
        </Breadcrumb>
        <h1 className='mb-2'>Serial Number Not Found</h1>
        {serialNumber != null && (
          <p>The serial number you entered <span className='fw-bold'>({serialNumber})</span> was not found.</p>
        )}
        <div className='mb-5'>
          <SearchBySerialNumber />
        </div>
        <h3 className='mb-2'>Browse Stuller Serialized Diamonds and Gemstones</h3>
        <ul className='mb-2'>
          <li><Link href='/products/diamonds-with-grading-report'>Natural Diamonds with Grading Report</Link></li>
          <li><Link href='/lab-grown-diamonds'>Lab-Grown Diamonds with Grading Report</Link></li>
          <li><Link href='/browse/gemstones/shop-by-stone-type/notable-gems/'>Notable Gems®</Link></li>
          <li><Link href='/browse/diamonds/specialty-diamonds/'>Specialty Diamonds</Link></li>
        </ul>
        <p className='mb-5'>Need help finding something? Call <a href='tel:+18008777777'>800-877-7777</a>, or use our <a className='cursor-pointer' onClick={handleOpenLiveChat}>Live Chat</a>.</p>
        <Button color='secondary' outline onClick={handleGoBack}>
          <FontAwesomeIcon icon={fas.faChevronLeft} className='me-2' />
          Back
        </Button>
        <div data-test='product-recommendation-carousel' className='pt-5'>
          <ProductRecommendationCarousel placements={PLACEMENTS} />
        </div>
      </div>
    </div>
  )
}

export {
  ErrorSerialized404
}
