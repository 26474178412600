import { type ReactElement } from 'react'
import { Button } from '@stuller/stullercom/ui'
import { MenuPopover, type MenuPopoverProps } from '../MenuPopover'

interface LogoutPopoverProps extends MenuPopoverProps {
}

/**
 * The popover for logout
 */
function LogoutPopover ({
  open,
  onOpenChange,
  reference
}: LogoutPopoverProps): ReactElement {
  return (
    <MenuPopover
      open={open}
      onOpenChange={onOpenChange}
      reference={reference}
    >
      <div className='p-6' data-gtm-click-subsection1='Log Out'>
        <Button href='/logout' tag='a' color='primary' outline pill className='w-100'>
          Log Out
        </Button>
      </div>
    </MenuPopover>
  )
}

export {
  LogoutPopover
}
