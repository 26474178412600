import {
  type ReactNode,
  forwardRef,
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  useCallback,
  type MutableRefObject,
  type CSSProperties
} from 'react'
import clsx from 'clsx'
import { useEventListener } from '@stuller/shared/util/react-hooks'
import { createPortal } from 'react-dom'
import { siteConfig } from '@stuller/stullercom/util/site-config'

const BootstrapOffcanvasImport = typeof window !== 'undefined' && (siteConfig.NODE_ENV === 'test' ? require('bootstrap/js/dist/offcanvas') : import('bootstrap/js/dist/offcanvas'))
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Data = require('bootstrap/js/dist/dom/data')

export interface OffcanvasProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Inline styles to pass to the containing element
   */
  style?: CSSProperties
  /**
   * Children of element
   */
  children?: ReactNode
  /**
   * Indicates the offcanvas is open
   */
  isOpen?: boolean
  /**
   * Handle called when offcanvas is toggled
   */
  onToggle?: (event: Event) => void
  /**
   * The placement of the offcanvas
   */
  placement?: 'start' | 'end' | 'top' | 'bottom'
  /**
   * Size of offcanvas non-default
   */
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
  /**
   * Indicates if offcanvas allows the backdrop to scroll
   */
  scroll?: boolean
  /**
   * Includes a offcanvas-backdrop element, use 'static' for a backdrop which doesn't close the offcanvas on click
   */
  backdrop?: boolean | 'static'
  /**
   * Closes the offcanvas when escape key is pressed
   */
  keyboard?: boolean
  /**
   * Ref to render the offcanvas to
   */
  portalRef?: MutableRefObject<Element | DocumentFragment | undefined>
  /**
   * Handler called when offcanvas is opening
   */
  onShow?: (event: Event) => void
  /**
   * Handler called once the offcanvas is shown
   */
  onShown?: (event: Event) => void
  /**
   * Handler called when the offcanvas is closing
   */
  onHide?: (event: Event) => void
  /**
   * Handler called once the offcanvas is hidden
   */
  onHidden?: (event: Event) => void
  /**
   * Handler called when `static` backdrop and the offcanvas has refused to close
   */
  onHidePrevented?: (event: Event) => void
}

/**
 * Offcanvas component to add dialogs for lightboxes, user notifications, or custom content.
 *
 * For easy offcanvas state management use `useToggle`.
 *
 * Only 1 offcanvas can be open at a time, nested offcanvass are not supported.
 *
 * **Note**: since Storybook uses iFrames on docs, each story uses a portal to render the offcanvas to the parent window, that mostly likely isn't needed in most situations.
 */
const Offcanvas = forwardRef<HTMLDivElement, OffcanvasProps>(({
  className,
  children,
  isOpen = false,
  onToggle,
  placement = 'start',
  size,
  scroll = false,
  backdrop = true,
  keyboard = true,
  portalRef,
  onShow = () => {},
  onShown = () => {},
  onHide = () => {},
  onHidden = () => {},
  onHidePrevented = () => {},
  ...otherAttributes
}, ref) => {
  const innerRef = useRef<HTMLDivElement>(null)
  // Expose innerRef, must use non-null assertion because signature requires it and conditional is not allowed
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  useImperativeHandle(ref, () => innerRef.current!, [])
  const [offcanvas, setOffcanvas] = useState<any>()
  const classNames = clsx(
    size != null ? `offcanvas-${size}` : 'offcanvas',
    `offcanvas-${placement}`,
    className
  )

  // Handle hide to toggle when closing
  const handleHide = useCallback((event: Event) => {
    if (isOpen) {
      onToggle?.(event)
    }
    onHide(event)
  }, [isOpen, onToggle, onHide])

  // Assign event listeners
  useEventListener('show.bs.offcanvas', onShow, innerRef)
  useEventListener('shown.bs.offcanvas', onShown, innerRef)
  useEventListener('hide.bs.offcanvas', handleHide, innerRef)
  useEventListener('hidden.bs.offcanvas', onHidden, innerRef)
  useEventListener('hidePrevented.bs.offcanvas', onHidePrevented, innerRef)

  // Init offcanvas
  // TODO: remove once SSR is safe https://github.com/twbs/bootstrap/pull/34989
  useEffect(() => {
    if (innerRef?.current != null && offcanvas == null && BootstrapOffcanvasImport !== false) {
      if (siteConfig.NODE_ENV !== 'test') {
        BootstrapOffcanvasImport?.then((bootstrapOffcanvas: any) => {
          const BootstrapOffcanvas = bootstrapOffcanvas.default
          setOffcanvas(new BootstrapOffcanvas(innerRef.current as Element, { backdrop, keyboard, scroll }))
        })
      } else {
        setOffcanvas(new BootstrapOffcanvasImport(innerRef.current as Element, { backdrop, keyboard, scroll }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerRef.current, portalRef?.current, offcanvas, backdrop, keyboard, scroll])

  // Dispose offcanvas
  useEffect(() => {
    return () => {
      if (siteConfig.NODE_ENV !== 'test') {
        offcanvas?.dispose?.()
      }
    }
  }, [offcanvas])

  // Toggle offcanvas
  useEffect(() => {
    if (offcanvas != null) {
      if (isOpen) {
        // Close all other offcanvass that are currently shown
        for (const offcanvasShown of document.querySelectorAll('.offcanvas.show')) {
          Data.get(offcanvasShown, 'bs.offcanvas')?.hide()
        }
        offcanvas.show()
      } else {
        offcanvas.hide()
      }
    }
  }, [offcanvas, isOpen])

  // The offcanvas to be rendered (in portal or not)
  const offcanvasInner = (
    <div
      className={classNames}
      tabIndex={-1}
      ref={innerRef}
      {...otherAttributes}
    >
      {children}
    </div>
  )

  if (portalRef?.current != null) {
    return createPortal(offcanvasInner, portalRef.current)
  }

  return offcanvasInner
})
Offcanvas.displayName = 'Offcanvas'

export {
  Offcanvas
}
