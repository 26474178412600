import React, { memo, type ReactElement, useMemo, useState } from 'react'
import Link from 'next/link'
import { Popover, PopoverBody, Table } from '@stuller/stullercom/ui'

export interface CmsContentDetails {
  /**
   * Main id of the content container (used for getting content)
   */
  ContentContainerId: number
  /**
   * Version of the content container (used for edit urls)
   */
  ContentId: number
  /**
   * Unique instance id of the content returned
   */
  InstanceId: string
  /**
   * Content type
   */
  ContentType: string
  /**
   * The title of the content container
   */
  Title: string
  /**
   * Edit path to the content in CMS
   */
  EditUrl: string
  /**
   * Direct children of the content container
   */
  ChildContent?: CmsContentDetails[]
}

export interface CmsContentPopoverProps {
  /**
   * The div element of the CMS content wrapper
   */
  reference: HTMLDivElement
  /**
   * The main div element returned from the CMS that contains the content (`reference.children[0]`)
   */
  editable: HTMLDivElement
}

interface DetailRowProps {
  /**
   * Details for the row
   */
  details: CmsContentDetails
  /**
   * Current padding to indent the row contents to indicate hierarchy
   */
  padding?: number
}

/**
 * A table row of content details
 */
function DetailRow ({
  details,
  padding = 0
}: DetailRowProps): ReactElement {
  return (
    <>
      <tr>
        <td style={{ paddingLeft: padding }}>
          <Link href={details.EditUrl} className='link-dark fw-normal' target='_blank' rel='noreferrer'>{details.Title}</Link>
        </td>
        <td>{details.ContentType}</td>
      </tr>

      {details.ChildContent?.map(child => <DetailRow key={child.InstanceId} details={child} padding={padding + 5} />)}
    </>
  )
}

/**
 * The CMS popover that show details about the CMS content for admins
 */
const CmsContentPopover = memo(({
  reference,
  editable
}: CmsContentPopoverProps): ReactElement => {
  const [open, handleOpenChange] = useState(false)
  const details = useMemo(() => {
    let data: CmsContentDetails | null = null

    try {
      data = JSON.parse(editable.dataset.cms ?? '{}')
    } catch (error) {}

    return data
  }, [editable])

  return (
    <Popover
      reference={reference}
      open={open}
      onOpenChange={handleOpenChange}
      hover
      arrow
      style={{
        maxWidth: 500,
        '--bs-popover-border-color': 'var(--bs-green-200)'
      }}
      data-gtm-click-section=''
    >
      <PopoverBody className='fs-6'>
        {details != null
          ? (
            <>
              <div className='fw-bold text-center'>{details.ContentContainerId} - Version: {details.ContentId}</div>
              <Table striped borderless responsive size='sm' className='fs-7 mb-0'>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  <DetailRow details={details} />
                </tbody>
              </Table>
            </>)
          : <b className='text-danger'>Error getting details</b>}
      </PopoverBody>
    </Popover>
  )
})
CmsContentPopover.displayName = 'CmsContentPopover'

export {
  CmsContentPopover
}
