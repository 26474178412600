import { forwardRef, useMemo } from 'react'
import { Pagination, type PaginationProps } from './Pagination'
import { PaginationItem } from './PaginationItem'
import { PaginationLink } from './PaginationLink'
import { PaginationEllipsis } from './PaginationEllipsis'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import clsx from 'clsx'

export interface UncontrolledPaginationProps extends PaginationProps {
  /**
   * Callback function to call when the page changes
   */
  onChange?: (page: number) => void
  /**
   * Callback function to get the URL of a page
   */
  getPageUrl?: (page: number) => string
  /**
   * Current page number
   */
  page: number
  /**
   * Number of items per page
   */
  perPage: number
  /**
   * Total number of items
   */
  total: number
  /**
   * Number of pages to show on either side of the current page
   */
  pagePadding?: 2 | 4 | 6 | 8
}

export interface getPagesProps {
  /**
   * Array of page numbers to render
   */
  pages: number[]
  /**
   * Total number of pages
   */
  totalPages: number
  /**
   * Whether to show the first page link
   */
  showFirstPage: boolean
  /**
   * Whether to show the last page link
   */
  showLastPage: boolean
  /**
   * Whether to show the first ellipsis
   */
  showFirstEllipsis: boolean
  /**
   * Whether to show the last ellipsis
   */
  showLastEllipsis: boolean
}

/**
 * Calculate what page links to render
 */
export function getPages (total: number, perPage: number, page: number, padding: number): getPagesProps {
  const totalPages = Math.ceil(total / perPage)
  const pages = [page]

  let pad = 1
  while (pages.length <= padding && pad <= padding) {
    const before = page - pad
    const after = page + pad
    if (before > 0) {
      pages.unshift(before)
    }
    if (after < totalPages + 1) {
      pages.push(after)
    }

    pad += 1
  }

  // Account for if ellipsis will only hide 1 page number and if so, show it instead
  const firstPage = pages[0]
  const lastPage = pages[pages.length - 1]
  if (firstPage === 3) {
    pages.unshift(2)
  }
  if (lastPage === totalPages - 2) {
    pages.push(totalPages - 1)
  }

  return {
    pages,
    totalPages,
    showFirstPage: pages[0] !== 1,
    showLastPage: pages[pages.length - 1] !== totalPages,
    showFirstEllipsis: !pages.includes(2),
    showLastEllipsis: !pages.includes(totalPages - 1)
  }
}

/**
 * Uncontrolled Pagination component that handles the display of pagination items based on the props passed through.
 */
const UncontrolledPagination = forwardRef<HTMLDivElement, UncontrolledPaginationProps>(({
  id,
  className,
  size,
  onChange,
  getPageUrl,
  page,
  perPage,
  total,
  pagePadding = 4,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(className)
  const {
    pages,
    totalPages,
    showFirstPage,
    showLastPage,
    showFirstEllipsis,
    showLastEllipsis
  } = useMemo(() => getPages(total, perPage, page, pagePadding), [total, perPage, page, pagePadding])

  const handleChange = (pageNumber: number): void => {
    if (pageNumber < 1 || pageNumber > totalPages) {
      return
    }

    onChange?.(pageNumber)
  }

  function handleGetPageUrl (page: number): string | undefined {
    if (getPageUrl !== undefined) {
      return getPageUrl(page)
    }

    return undefined
  }

  return (
    <Pagination className={classNames} id={id} aria-label='Page navigation' size={size} ref={ref} {...otherAttributes}>
      <PaginationItem disabled={page === 1}>
        <PaginationLink onClick={() => { handleChange(page - 1) }} href={handleGetPageUrl(page - 1)}>
          <FontAwesomeIcon icon={fas.faCaretLeft} size={size === 'sm' ? 'sm' : 'xl'} />
        </PaginationLink>
      </PaginationItem>
      {showFirstPage && (
        <PaginationItem>
          <PaginationLink
            href={handleGetPageUrl(1)}
            onClick={() => { handleChange(1) }}
          >
            1
          </PaginationLink>
        </PaginationItem>
      )}
      {showFirstEllipsis && (
        <PaginationEllipsis size={size} />
      )}
      {pages.map((pageNumber: number) => (
        <PaginationItem key={pageNumber} active={pageNumber === page}>
          <PaginationLink
            href={handleGetPageUrl(pageNumber)}
            onClick={() => { handleChange(pageNumber) }}
          >
            {pageNumber}
          </PaginationLink>
        </PaginationItem>
      ))}
      {showLastEllipsis && (
        <PaginationEllipsis size={size} />
      )}
      {showLastPage && (
        <PaginationItem>
          <PaginationLink
            href={handleGetPageUrl(totalPages)}
            onClick={() => { handleChange(totalPages) }}
          >
            {totalPages}
          </PaginationLink>
        </PaginationItem>
      )}
      <PaginationItem disabled={page === totalPages}>
        <PaginationLink onClick={() => { handleChange(page + 1) }} href={handleGetPageUrl(page + 1)}>
          <FontAwesomeIcon icon={fas.faCaretRight} size={size === 'sm' ? 'sm' : 'xl'} />
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  )
})

export {
  UncontrolledPagination
}
