import { type Dispatch, type ReactElement, type ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { type IconProp } from '@fortawesome/fontawesome-svg-core'
import clsx from 'clsx'
import variables from '@stuller/stullercom/ui/styles/variables.module.scss'

interface MenuItemProps {
  /**
   * Menu item icon
   */
  icon: IconProp
  /**
   * Text to display under the menu item icon
   */
  text: string
  /**
   * Indicates to show the popover
   */
  open: boolean
  /**
   * Badge or icon that appears in the top right corner of the menu item icon
   */
  children?: ReactNode
  /**
   * Fired when the menu item is clicked
   */
  onClick: () => void
  /**
   * The reference element (not a ref) to map the popover to
   */
  setReference: Dispatch<any>
}

/**
 * Menu item with icon/text
 */
function MenuItem ({
  icon,
  text,
  open,
  children,
  onClick,
  setReference
}: MenuItemProps): ReactElement | null {
  const classNames = clsx(
    'text-center flex-fill flex-sm-grow-0 position-relative border-start border-gray-200 p-0 m-0 cursor-pointer user-select-none',
    open ? 'bg-blue-400 text-white' : 'bg-gray-500-hover text-white-hover'
  )

  return (
    <>
      <li
        className={classNames}
        onClick={onClick}
        ref={setReference}
      >
        <div className='d-block py-4 py-md-3 px-4 px-md-2 position-relative'>
          <span className='position-relative'>
            <FontAwesomeIcon icon={icon} className='fw-medium text-gray-500 pt-1' style={{ fontSize: 21 }} />
            <span className='position-absolute top-0 start-100 translate-middle ms-2 mt-n2'>
              {children}
            </span>
          </span>
          <span className='d-none d-sm-block fs-6' data-test={text}>{text}</span>
        </div>
      </li>

      <style jsx>{`
        li:hover div > span > :global(svg),
        li.bg-blue-400 div > span > :global(svg) {
          color: var(--bs-white) !important;
        }

        @media (min-width: ${variables.breakpointSm}) {
          li {
            min-width: 95px;
          }
        }
      `}
      </style>
    </>
  )
}

export {
  MenuItem
}
