import React, { type ReactElement } from 'react'
import { type SimpleImageInfoFragment, type SimpleImageWithLinkInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { isStringEmpty } from '@stuller/shared/util/core'
import clsx from 'clsx'

interface ContentstackSimpleImageProps {
  data?: SimpleImageInfoFragment | null
  /**
   * Optional class name which can be set by other components when reusing the Simple Image component
   */
  className?: string
  /**
   * The style of the Simple Image
   */
  style?: React.CSSProperties
}

/**
 * Helper function to not render any parent elements when the image field is empty
 */
function isSimpleImageEmpty (data?: SimpleImageInfoFragment | SimpleImageWithLinkInfoFragment | null): boolean {
  return isStringEmpty(data?.image_source)
}

/**
 * A CMS user should be able to add a Simple Image to a page.
 *
 * The Simple Image should match designs and have all configurations matching the build kit.
 */
function ContentstackSimpleImage ({ data, className, style }: ContentstackSimpleImageProps): ReactElement | null {
  const classNames = clsx('img-fluid', className)
  const {
    alt_text: altText,
    image_source: imageSource
  } = data ?? {}

  if (isSimpleImageEmpty(data)) {
    return null
  }

  return (
    <img
      style={style}
      alt={altText ?? ''}
      className={classNames}
      src={imageSource ?? ''}
    />
  )
}

export {
  ContentstackSimpleImage,
  isSimpleImageEmpty
}
