import { Alert, AlertHeading, type ThemeColor } from '@stuller/stullercom/ui'
import { ContentstackButton, isButtonEmpty } from '../contentstack-button/ContentstackButton'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import { stringToHtmlId } from '@stuller/shared/util/core'
import { type AlertInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { type ReactNode } from 'react'

interface ContentstackAlertProps {
  data?: AlertInfoFragment | null
}

function ContentstackAlert ({ data }: ContentstackAlertProps): ReactNode | null {
  const { appearance, body, title } = data ?? {}

  const hasHeading = !isRteEmpty(body?.heading?.json)
  const hasRichText = !isRteEmpty(body?.rich_text?.json)
  const hasBottomButton = !isButtonEmpty(body?.button)
  const showIcon = appearance?.hide_icon === false
  const themeColor = appearance?.color_theme as ThemeColor

  if (!hasHeading && !hasRichText) {
    return null
  }

  const alertButton = hasBottomButton ? <ContentstackButton data={body?.button} /> : null

  return (
    <Alert color={themeColor} id={stringToHtmlId(title, 'alert-')} icon={showIcon} button={alertButton}>
      <div className='row gx-3'>
        <div className='col'>
          <div className='row gy-3'>
            <div className='col'>
              {hasHeading && (
                <AlertHeading>
                  <ContentstackJsonRte excludeOuterTags json={body?.heading?.json} />
                </AlertHeading>
              )}
              {hasRichText && (
                <ContentstackJsonRte excludeOuterTags json={body?.rich_text?.json} />
              )}
            </div>
          </div>
        </div>

      </div>
    </Alert>
  )
}

export { ContentstackAlert }
