import { type Auth } from '@stuller/stullercom/feat/auth'
import type {
  CartLineTrackingFragment,
  CartListItemFragment,
  GetCartHeaderQuery,
  GetCartQuery,
  SavedCartLineTrackingFragment
} from '@stuller/stullercom/data-access/apollo-queries'
import { trackEvent } from '../track'
import type { ItemTracking } from './types'

/**
 * Type for cartLineTrackingMapper
 */
type CartLineTracking = CartLineTrackingFragment | SavedCartLineTrackingFragment

/**
 * Helper Method to map GraphQL cart data to ItemTracking object
 */
function cartLineTrackingMapper (cartLine: CartLineTracking, auth: Auth, quantityDiff?: number): ItemTracking | null {
  if (!isTrackableItem(cartLine.item.__typename)) {
    return null
  }
  const { __typename: typename } = cartLine.item
  const { isShowcase } = auth

  const price = isShowcase
    ? null
    : cartLine.pricePerUnit?.discount?.originalPrice.valueInUsd ??
          cartLine.pricePerUnit?.total.valueInUsd ??
          null
  const discount = price != null ? cartLine.pricePerUnit?.discount?.value.valueInUsd ?? 0 : null

  return {
    item_id: cartLine.productGroup?.code ?? cartLine.item.defaultProductGroup?.code ?? cartLine.item.series,
    item_variant: cartLine.item.itemNumber,
    item_name: cartLine.item.title,
    item_brand: cartLine.item.brand ?? null,
    item_category: cartLine.item.merchandiseHierarchy?.segment2 ?? null,
    item_category2: cartLine.item.merchandiseHierarchy?.segment3 ?? null,
    item_category3: cartLine.item.merchandiseHierarchy?.segment4 ?? null,
    item_category4: cartLine.item.merchandiseHierarchy?.segment5 ?? null,
    item_category5: cartLine.item.merchandiseHierarchy?.segment6 ?? null,
    currency: price != null ? 'USD' : null,
    discount,
    price,
    quantity: quantityDiff ?? cartLine.options.quantity,
    config_id: typename === 'Configuration' ? Number(cartLine.item.id) : null,
    serial_number: typename === 'SerializedProduct' ? Number(cartLine.item.id) : null,
    coupon: null,
    index: cartLine.trackingSource?.index ?? null,
    item_list_id: cartLine.trackingSource?.source ?? null,
    item_list_name: null
  }
}

function cartListItemTrackingMapper (cartListItem: CartListItemFragment, auth: Auth): ItemTracking[] {
  return cartListItem.items.map(cartLine => cartLineTrackingMapper(cartLine, auth)).filter((item): item is ItemTracking => item !== null)
}

function isTrackableItem (typename: string | undefined): boolean {
  return typename === 'Product' || typename === 'SerializedProduct' || typename === 'Configuration'
}

/**
 * Track view cart event
 */
function trackViewCart (cart: GetCartHeaderQuery['cart'] | GetCartQuery['cart'], auth: Auth): void {
  const value = auth.isShowcase ? null : cart?.price?.subtotal.valueInUsd ?? null
  let cartLines: CartLineTracking[] = []

  if (cart != null) {
    if ('shipments' in cart) {
      cartLines = cart?.shipments.map(shipment => shipment.lines).flat()
    } else {
      cartLines = cart.items
    }
  }

  trackEvent('view_cart', {
    value,
    currency: value != null ? 'USD' : null,
    items: cartLines.map(x => cartLineTrackingMapper(x, auth))
      .filter(i => i != null) ?? []
  })
}

/**
 * Track add to cart event
 */
function trackAddToCart (cartLine: CartLineTracking, auth: Auth, quantityDiff?: number): void {
  const value = auth.isShowcase ? null : cartLine?.price?.total.valueInUsd ?? null

  trackEvent('add_to_cart', {
    value,
    currency: value != null ? 'USD' : null,
    items: [cartLineTrackingMapper(cartLine, auth, quantityDiff)]
      .filter(i => i != null)
  })
}

/**
 * Track add to cart event for multiple items
 */
function trackAddToMultipleCart (cartList: CartListItemFragment, auth: Auth): void {
  const value = auth.isShowcase ? null : cartList?.price?.subtotal.valueInUsd ?? null

  trackEvent('add_to_cart', {
    value,
    currency: value != null ? 'USD' : null,
    items: cartListItemTrackingMapper(cartList, auth).filter(i => i != null)
  })
}

/**
 * Track remove from cart event
 */
function trackRemoveFromCart (cartLine: CartLineTracking, auth: Auth, quantityDiff?: number): void {
  const value = auth.isShowcase ? null : cartLine?.price?.total.valueInUsd ?? null

  trackEvent('remove_from_cart', {
    value: cartLine?.price?.total.valueInUsd,
    currency: value != null ? 'USD' : null,
    items: [cartLineTrackingMapper(cartLine, auth, quantityDiff)]
      .filter(i => i != null)
  })
}

/**
 * Track add to wishlist event
*/
function trackAddToWishList (cartLine: CartLineTracking, auth: Auth): void {
  trackEvent('add_to_wishlist', {
    items: [cartLineTrackingMapper(cartLine, auth)]
      .filter(i => i != null)
  })
}

export {
  trackViewCart,
  trackAddToCart,
  trackAddToMultipleCart,
  trackRemoveFromCart,
  cartLineTrackingMapper,
  cartListItemTrackingMapper,
  trackAddToWishList
}
