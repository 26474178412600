import { type CSSProperties, type ReactNode, forwardRef } from 'react'
import { clsx } from 'clsx'

interface CardFooterType {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Inline styles to pass to the containing element
   */
  style?: CSSProperties
  /**
   * Children of element
   */
  children?: ReactNode
}

/**
 * React implementation/wrapper of bootstrap's 'card-footer' component to appear at the bottom of a Card
 * this portion of the Card will have a gray background
 */
const CardFooter = forwardRef<HTMLDivElement, CardFooterType>(({
  className,
  children,
  ...otherAttributes
}, ref) => {
  const classNames = clsx('card-footer', className)

  return (
    <div
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </div>
  )
})
CardFooter.displayName = 'CardFooter'

export {
  CardFooter
}
