import { type ReactElement } from 'react'
import { useCurrency } from '@stuller/shared/util/react-hooks'
import { type PriceBreak } from './types'

interface AddToCartQuantityDiscountRowProps {
  priceBreak: PriceBreak
}

/**
 * Renders individual price break information
 */
function AddToCartQuantityDiscountRow ({ priceBreak }: AddToCartQuantityDiscountRowProps): ReactElement | null {
  const [priceBreakPrice] = useCurrency(priceBreak.price?.value)
  if (priceBreak.price == null || priceBreak.minQuantity == null) {
    return null
  }

  return (
    <tr>
      <td className='text-start'>
        {priceBreak?.maxQuantity != null ? `${priceBreak.minQuantity}–${priceBreak.maxQuantity}` : `${priceBreak.minQuantity}+`}
      </td>
      <td>
        {priceBreakPrice}
      </td>
      <td>
        <span className='text-red-500 fw-bold'>
          {priceBreak.discount != null && priceBreak.discount.percent !== 0 && `Save ${priceBreak.discount.percent}%`}
        </span>
      </td>
    </tr>
  )
}

export {
  AddToCartQuantityDiscountRow
}
