import { type ReactElement } from 'react'
import { AddToCartButton } from './AddToCartButton'
import { type AddToCartSectionInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'
import { AddToCartQuantityDiscounts } from './AddToCartQuantityDiscounts'
import { AddToCartPrice } from './AddToCartPrice'

interface AddToCartSectionProps {
  addToCartSectionInfo: AddToCartSectionInfoFragment
}

/**
 * Renders the Add to Cart Partial
 */
function AddToCartSection ({ addToCartSectionInfo }: AddToCartSectionProps): ReactElement {
  const { price } = addToCartSectionInfo

  return (
    <div className='bg-gray-100 p-4'>
      <div>
        {addToCartSectionInfo.canAddToCart === true ? 'Can Add To Cart' : 'CANNOT Add To Cart'}
      </div>
      <AddToCartPrice addToCartSectionInfo={addToCartSectionInfo} />
      <div>
        {addToCartSectionInfo.id}
      </div>
      <AddToCartQuantityDiscounts priceBreaks={price?.priceBreaks} />
      <AddToCartButton />
    </div>
  )
}

export {
  AddToCartSection
}
