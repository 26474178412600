import {
  type ReactElement,
  useCallback,
  type ChangeEvent,
  useMemo,
  useState
} from 'react'
import { Input } from '../input/Input'
import InputMask from 'react-input-mask'

export interface MonthYearInputProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Input name field
   */
  name: string
  /**
   * Input value field
   */
  value: string
  /**
   * Input required field
   */
  required?: boolean
  /**
   * Input onChange event
   */
  onChange: (date: string, invalid: boolean, expirationMonth: number, expirationYear: number) => void
  /**
   * Invalid styling
   */
  invalid?: boolean
  /**
   * Valid styling
   */
  valid?: boolean
}

/**
 * Get the month and year input from the user
 */
function MonthYearInput ({ id, name, value, required, onChange, invalid, valid, ...otherAttributes }: MonthYearInputProps): ReactElement {
  const [internalValue, setInternalValue] = useState<string>(value)
  const [monthYearIsValid, setMonthYearIsValid] = useState<boolean>(true)
  const monthYearMask = useMemo(() => {
    if (internalValue.startsWith('0') || internalValue === '' || internalValue === null || internalValue === undefined) {
      return [/[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/]
    }

    return [/1/, /[0-2]/, '/', /[0-9]/, /[0-9]/]
  }, [internalValue])

  /**
   * Callback to handle the month and year value change
   */
  const handleMonthYearChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setInternalValue(event.target.value)
    setMonthYearIsValid(event.target.value != null)
    const month = parseInt(event.target.value.split('/')[0], 10)
    const year = parseInt('20' + event.target.value.split('/')[1], 10)
    onChange(event.target.value, !monthYearIsValid, month, year)
  }, [onChange, monthYearIsValid])

  /**
   * Handle the month and year value formatting
   */
  const beforeMaskedStateChange = useCallback(({ currentState, nextState }: { currentState: any, nextState: any }) => {
    if (currentState?.value != null && currentState.value.length === 7) {
      const currentStateSplit = currentState.value.split('/')
      const mm: string = currentStateSplit != null && currentStateSplit.length > 0 ? currentStateSplit[0].toString() : ''
      const year: string = currentStateSplit != null && currentStateSplit.length > 1 ? currentStateSplit[1].toString() : ''

      return {
        ...currentState,
        value: `${mm}/${year.substring(2)}`
      }
    }

    return nextState
  }, [])

  return (
    <InputMask
      mask={monthYearMask}
      maskPlaceholder={null}
      beforeMaskedStateChange={beforeMaskedStateChange}
      onChange={handleMonthYearChange}
      value={value}
      {...otherAttributes}
    >
      <Input
        id={id}
        name={name}
        placeholder='MM/YY'
        required={required}
        type='tel'
        autoComplete='off'
        autoCorrect='off'
        spellCheck='false'
        className='c-gray-dk-3'
        invalid={!monthYearIsValid || invalid}
        valid={valid}
        data-test='expiry-date'
      />
    </InputMask>
  )
}

export { MonthYearInput }
