import { forwardRef, type HTMLAttributes, type ReactNode } from 'react'
import { Card } from '../../shared/card/Card'
import { CardBody } from '../../shared/card/CardBody'
import clsx from 'clsx'
import Link from 'next/link'
import { IMAGE_COMING_SOON } from '@stuller/shared/util/constants'
import { ProductCardPrice } from './ProductCardPrice'

interface ExchangeRate {
  code: string
  culture: string
}

export interface TotalPrice {
  value: number
  exchangeRate: ExchangeRate
}

export interface DiscountPrice {
  originalPrice: TotalPrice
  percent: number
}

export interface ProductData {
  /**
  * Product ID
  */
  id: string
  /**
   * Product name
   */
  title: string
  /**
   * Product Image URL
   */
  image: string
  /**
   * Product url
   */
  url: string
  /**
   * Data attribute for GTM click tracking
   */
  clickTrackingURL?: string
  /**
   * Item Number
   */
  itemNumber?: string
  /**
   * Group Id
   */
  groupId?: string
  /**
   * Optional onClick event
   */
  onClick?: HTMLAttributes<HTMLAnchorElement>['onClick']
  /**
   * Total Price of an product
   */
  total?: TotalPrice
  /**
   * Maximum price of a product group
   */
  totalMax?: TotalPrice
  /**
   * Discount of a product or product group
   */
  discount?: DiscountPrice | null
}

type ProductCardProps = ProductData & {
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
}

/**
 * A [Card](?path=/docs/components-card--docs) based component made for displaying product data in search results, carousels, etc.
 */
const ProductCard = forwardRef<HTMLDivElement, ProductCardProps>(({
  className,
  children,
  title,
  image,
  url,
  id,
  groupId,
  itemNumber,
  clickTrackingURL,
  total,
  totalMax,
  discount,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    'border-gray-500-hover',
    className
  )

  return (
    <Card
      className={classNames}
      ref={ref}
      style={{ width: '220px' }}
      {...otherAttributes}
    >
      <CardBody className='p-4'>
        <span className='visually-hidden'>{id}</span>
        <img className='w-100' src={image !== '' ? image : IMAGE_COMING_SOON} alt={title} />
        <div className='pt-3'>
          <ProductCardPrice
            total={total}
            totalMax={totalMax}
            discount={discount}
          />
          <Link
            href={url}
            className='stretched-link text-decoration-none text-body text-decoration-underline-hover fw-normal line-clamp'
            data-gtm-click-track-url={clickTrackingURL}
          >
            {title}
          </Link>
        </div>
      </CardBody>
    </Card>
  )
})
ProductCard.displayName = 'ProductCard'

export {
  ProductCard
}
