import { ContentstackCard } from './ContentstackCard'
import { MultiItemCarousel, MultiItemCarouselControl, MultiItemCarouselIndicators, MultiItemCarouselInner, MultiItemCarouselItem, useMultiItemCarousel } from '@stuller/stullercom/ui'
import { stringToHtmlId } from '@stuller/shared/util/core'
import { type CardGroupInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { type ReactElement } from 'react'
import clsx from 'clsx'

interface ContentstackCardGroupDisplayProps {
  appearance?: CardGroupInfoFragment['appearance']
  cards?: CardGroupInfoFragment['cards'] | null
  className?: string
}

function ContentstackCardGroupDisplay ({
  appearance,
  cards,
  className
}: ContentstackCardGroupDisplayProps): ReactElement | null {
  const multiItemCarouselProps = useMultiItemCarousel()
  if (appearance?.display_type === 'carousel') {
    const carouselClassNames = clsx(
      'pb-4 pb-md-0',
      className
    )

    return (
      <MultiItemCarousel {...multiItemCarouselProps}>
        <MultiItemCarouselInner gap='2rem' className={carouselClassNames}>
          {cards?.map((card, index) => (
            <MultiItemCarouselItem id={stringToHtmlId(card?.title, 'card-')} key={index}>
              <ContentstackCard
                card={card}
                alignmentClass={`text-${appearance?.card_alignment}`}
                index={index}
              />
            </MultiItemCarouselItem>
          ))}
        </MultiItemCarouselInner>
        <MultiItemCarouselControl direction='prev' />
        <MultiItemCarouselControl direction='next' />
        <MultiItemCarouselIndicators />
      </MultiItemCarousel>
    )
  }

  return (
    <>
      {cards?.map((card, index) => (
        <div key={index}>
          <ContentstackCard
            card={card}
            alignmentClass={`text-${appearance?.card_alignment}`}
          />
        </div>
      ))}
    </>
  )
}

export {
  ContentstackCardGroupDisplay
}
