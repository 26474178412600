import { forwardRef, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { far, fas } from '@awesome.me/kit-3dbd93c064/icons'
import { Input, type InputProps, InputGroup, InputGroupText } from '../../../..'

interface DatePickerInputProps extends InputProps {
  /**
   * Whether to return only the input or the input in the standard input group
   */
  inputOnly: boolean
  /**
   * Whether this input is only for time selection
   */
  isTimeOnly: boolean
}

/**
 * The custom input component that is used with the DatePicker component
 */
const DatePickerInput = forwardRef<HTMLInputElement, DatePickerInputProps>(({
  pill,
  size,
  invalid,
  valid,
  inputOnly,
  isTimeOnly,
  onClick,
  ...otherAttributes
}, ref) => {
  const inputComponent = useMemo(() => (
    <Input
      type='text'
      pill={inputOnly ? pill : undefined}
      size={inputOnly ? size : undefined}
      invalid={invalid}
      valid={valid}
      onClick={onClick}
      ref={ref}
      {...otherAttributes}
    />
  ), [inputOnly, pill, size, invalid, valid, onClick, ref, otherAttributes])

  if (inputOnly) {
    return inputComponent
  }

  return (
    <InputGroup
      pill={pill}
      size={size}
      invalid={invalid}
      valid={valid}
    >
      {isTimeOnly && (
        <InputGroupText className='cursor-pointer' onClick={onClick}>
          <FontAwesomeIcon icon={far.faClock} size='lg' />
        </InputGroupText>
      )}
      {inputComponent}
      {!isTimeOnly && (
        <InputGroupText className='cursor-pointer' onClick={onClick}>
          <FontAwesomeIcon icon={fas.faCalendarAlt} size='lg' />
        </InputGroupText>
      )}
    </InputGroup>
  )
})
DatePickerInput.displayName = 'DatePickerInput'

export {
  DatePickerInput
}
