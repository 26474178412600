import { type ReactElement } from 'react'
import { ContentstackJsonRte } from '@stuller/stullercom/feat/contentstack-json-rte'

interface ContentstackTableProps {
  json: any
  tableStyle?: Array<string | null> | null
}

/**
 * A CMS user should be able to add a group of Stone Shape Icons to a content page
 * The Stone Shape Icons is a collection of Stone Shape Cards that optionally include a link to a page.
 */
function ContentstackTable ({ json, tableStyle }: ContentstackTableProps): ReactElement | null {
  return (
    <ContentstackJsonRte json={json} tableStyle={tableStyle} table />
  )
}

export {
  ContentstackTable
}
