import { type ReactElement } from 'react'
import { AddToCartSection } from '@stuller/stullercom/feat/add-to-cart'
import { type AddToCartSectionInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'

interface SerializedDiamondAddToCartProps {
  addToCartInfo: AddToCartSectionInfoFragment
}

function SerializedDiamondAddToCart ({ addToCartInfo }: SerializedDiamondAddToCartProps): ReactElement {
  return (
    <AddToCartSection addToCartSectionInfo={addToCartInfo} />
  )
}

export {
  SerializedDiamondAddToCart
}
