import { type ReactElement } from 'react'
import Link from 'next/link'
import {
  type JewelerShowcaseCallbackRequestInfoFragment,
  useGetPendingShowcaseCallbackRequestsQuery
} from '@stuller/stullercom/data-access/apollo-queries'
import { Card, CardBody, CardSubtitle, CardTitle, Loading } from '@stuller/stullercom/ui'
import { dayjs } from '@stuller/shared/util/core'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface CallbackRequestCardProps extends JewelerShowcaseCallbackRequestInfoFragment {
}

interface SeeMoreProps {
  countRemaining: number
}

/**
 * Show link to see more callback requests
 */
function SeeMore ({ countRemaining }: SeeMoreProps): ReactElement {
  return (
    <div className='row p-1 w-100'>
      <div className='text-center col-12'>
        <Link href='/myaccount/showcasesettings/pastcallbackrequests'>
          +{countRemaining} more
        </Link>
      </div>
    </div>
  )
}

/**
 * Leverages Card components to display a notification of a showcase callback request
 */
function CallbackRequestCard ({ creationDate, emailAddress, id }: CallbackRequestCardProps): ReactElement {
  const creationDateDayJs = dayjs(creationDate)

  return (
    <Card className='mb-3 border border-5 border-top-0 border-end-0 border-bottom-0 border-blue-400'>
      <CardBody className='row align-items-center'>
        <div className='col overflow-hidden'>
          <CardTitle className='fs-base fw-bold mb-0 position-relative'>
            <FontAwesomeIcon className='me-2 text-blue-400' icon={fas.faTags} />
            Showcase Inquiry
            <span className='card-time fw-medium text-body-secondary position-absolute end-0' title={creationDateDayJs.format('llll')}>
              {creationDateDayJs.toShortTimeFromNowString()}
            </span>
          </CardTitle>
          <CardSubtitle className='fs-base fw-light'>
            From: {emailAddress}
          </CardSubtitle>
        </div>
        <div className='col-auto'>
          <Link
            href={`/myaccount/showcasesettings/viewcallbackrequest/?id=${id}`}
            className='btn btn-sm btn-secondary rounded-pill'
          >
            Respond
          </Link>
        </div>
      </CardBody>
    </Card>
  )
}

/**
 * Displays a list of callback requests (3 at most) leveraging the shared Card component
 */
function ShowcaseCallbackRequests (): ReactElement | null {
  const { previousData, data = previousData, loading, error } = useGetPendingShowcaseCallbackRequestsQuery({
    variables: {
      paging: {
        limit: 3,
        page: 1
      },
      onlyRecent: true
    },
    fetchPolicy: 'cache-and-network'
  })
  const pendingCount = data?.pendingJewelerShowcaseCallbackRequests?.count
  const callbackRequests = data?.pendingJewelerShowcaseCallbackRequests?.results
  const countRemaining = pendingCount != null && callbackRequests != null ? pendingCount - callbackRequests.length : 0

  if (error != null || pendingCount === 0) {
    return null
  }

  return (
    <Loading loading={loading && data == null} className='mb-4'>
      {callbackRequests?.map(r => <CallbackRequestCard {...r} key={r.id} />)}
      {countRemaining > 0 && <SeeMore countRemaining={countRemaining} />}
    </Loading>
  )
}

export {
  ShowcaseCallbackRequests
}
