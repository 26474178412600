import { type ReactNode } from 'react'
import { type SectionHeaderInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { ContentstackJsonRte, isRteEmpty } from '@stuller/stullercom/feat/contentstack-json-rte'
import clsx from 'clsx'
import { isStringEmpty } from '@stuller/shared/util/core'
import { ContentstackIcon } from '../contentstack-icon/ContentstackIcon'

interface ContentstackSectionHeaderProps {
  data?: SectionHeaderInfoFragment | null
  className?: string
}

function ContentstackSectionHeader ({ data, className }: ContentstackSectionHeaderProps): ReactNode | null {
  const { appearance, body, header } = data ?? {}

  const icon = header?.heading_icon
  const isHeadingEmpty = isRteEmpty(header?.heading?.json)
  const isBodyEmpty = isRteEmpty(body?.rich_text?.json)

  if (isHeadingEmpty && isBodyEmpty) {
    return null
  }

  const textClasses = clsx(
    appearance?.text_position === 'left' && 'text-start',
    appearance?.text_position === 'center' && 'text-center',
    appearance?.text_position === 'right' && 'text-end',
    appearance?.text_color === 'white' && 'text-white links-white',
    'px-4 px-md-0',
    className
  )

  const headingClasses = clsx(
    textClasses,
    appearance?.heading_font_size ?? ''
  )

  const containerClasses = clsx(
    'col-12 w-100 mb-n3 d-flex',
    appearance?.text_position === 'left' && 'justify-content-start',
    appearance?.text_position === 'center' && 'justify-content-center',
    appearance?.text_position === 'right' && 'justify-content-end'
  )

  return (
    <div className={containerClasses}>
      <div style={{ maxWidth: '800px' }}>
        {!isHeadingEmpty && (
          <h2 className={headingClasses}>
            {!isStringEmpty(icon) &&
              <ContentstackIcon
                icon={data?.header?.heading_icon}
                className='me-2'
              />}
            <ContentstackJsonRte excludeOuterTags json={header?.heading?.json} />
          </h2>
        )}
        {!isBodyEmpty && (
          <p className={textClasses}>
            <ContentstackJsonRte excludeOuterTags json={body?.rich_text?.json} />
          </p>
        )}
      </div>
    </div>
  )
}

export { ContentstackSectionHeader }
