import { ContentstackTopicBody } from './ContentstackTopicBody'
import { ContentstackTopicFeaturedMedia } from './ContentstackTopicFeaturedMedia'
import { ContentstackTopicHeader } from './ContentstackTopicHeader'
import { type ReactElement } from 'react'
import { type TopicInfoFragment } from '@stuller/stullercom/data-access/apollo-queries-contentstack'
import { useContentstackTargeting } from '@stuller/stullercom/feat/contentstack-targeting'
import clsx from 'clsx'

interface ContentstackTopicRowProps {
  data?: TopicInfoFragment | null
}

const columnClasses: Record<string, string> = {
  half: 'col-md-6',
  'two-thirds': 'col-md-8'
}

/**
 * Component for rendering the contents inside the Topic created in Contentstack
 */
function ContentstackTopicRow ({ data }: ContentstackTopicRowProps): ReactElement | null {
  const {
    appearance,
    header,
    featured_media: featuredMedia,
    body,
    targetingConnection
  } = data ?? {}

  const {
    text_color: textColor,
    text_position: textPosition,
    text_width: textWidth,
    button_orientation: buttonOrientation,
    hide_featured_media_desktop: hideFeaturedMediaDesktop
  } = appearance ?? {}

  // Check for targeting rules.
  // Must be the last hook in the component since it short circuits the rendering and hooks cannot be called conditionally.
  const showContent = useContentstackTargeting(targetingConnection?.edges?.[0]?.node?.targeting)
  if (!showContent || data == null) {
    return null
  }

  const rowClasses = clsx(
    'row',
    'g-5 gx-md-6',
    appearance?.vertical_alignment === true && 'align-items-center',
    textPosition === 'center' && 'flex-column-reverse align-items-center',
    textPosition === 'left' && 'flex-column-reverse flex-md-row',
    textPosition === 'right' && 'flex-column-reverse flex-md-row-reverse'
  )

  const topicContentClasses = clsx(
    'col',
    textColor !== 'body' && 'text-white',
    textWidth != null ? columnClasses[textWidth] : 'col-md-12'
  )

  const featuredMediaClassNames = clsx(
    'col my-auto d-flex justify-content-center',
    textPosition === 'center' && 'mb-md-4 col-md-6',
    hideFeaturedMediaDesktop === true && 'd-md-none'
  )

  return (
    <div className={rowClasses}>
      {(header != null || body != null) && (
        <div className={topicContentClasses}>
          <ContentstackTopicHeader
            data={header}
            textPosition={textPosition ?? ''}
            headingFontSize={appearance?.heading_font_size}
          />
          {body != null && (
            <ContentstackTopicBody
              buttonOrientation={buttonOrientation ?? 'horizontal'}
              textPosition={textPosition ?? ''}
              data={body}
            />
          )}
        </div>
      )}
      <ContentstackTopicFeaturedMedia
        data={featuredMedia}
        className={featuredMediaClassNames}
      />
    </div>
  )
}

export {
  ContentstackTopicRow
}
