import {
  useState,
  useCallback,
  useRef,
  useMemo,
  type MutableRefObject
} from 'react'

export type IntersectionObserverOptions = Omit<IntersectionObserverInit, 'root'>

type IntersectionObserverHookRootRefNode = any

export type IntersectionObserverHookRef = (node: HTMLElement | null) => void

export type IntersectionObserverHookRootRef = MutableRefObject<IntersectionObserverHookRootRefNode>

export type UseIntersectionObserver = [
  /**
   * Custom ref callback to handle the intersection observer
   */
  IntersectionObserverHookRef,
  {
    /**
     * The current intersection entry
     */
    entry: IntersectionObserverEntry | undefined
    /**
     * The ref to the root element to track intersection
     */
    rootRef: IntersectionObserverHookRootRef
  }
]

/**
 * Hook to help easily track if a component is visible or not
 * Can be used to create lazy loading images, trigger animations on entering or leaving the screen etc
 * See https://github.com/onderonur/react-intersection-observer-hook
 */
function useIntersectionObserver (args?: IntersectionObserverOptions): UseIntersectionObserver {
  const rootMargin = useMemo(() => args?.rootMargin ?? '0px', [args?.rootMargin])
  const threshold = useMemo(() => args?.threshold ?? [0], [args?.threshold])
  const rootRef = useRef<IntersectionObserverHookRootRefNode>(null)
  const observerRef = useRef<IntersectionObserver | null>(null)
  const [entry, setEntry] = useState<IntersectionObserverEntry>()
  const root = rootRef.current

  /**
   * Custom ref callback to handle the intersection observer
   */
  const customRef = useCallback((node: HTMLElement | null) => {
    if (observerRef.current != null) {
      observerRef.current.disconnect()
      observerRef.current = null
    }

    if (node != null) {
      const observer = new IntersectionObserver(([entry]) => {
        setEntry(entry)
      }, { threshold, root, rootMargin })

      observer.observe(node)
      observerRef.current = observer
    }
  }, [threshold, root, rootMargin])

  return [customRef, { entry, rootRef }]
}

export {
  useIntersectionObserver
}
