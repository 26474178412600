import { type ReactElement } from 'react'
import { Button } from '@stuller/stullercom/ui'

/**
 * Component description
 */
function AddToCartButton (): ReactElement {
  return (
    <Button color='success'>Add to Cart</Button>
  )
}

export {
  AddToCartButton
}
