import { useMemo } from 'react'
import { useUserAgent } from '@stuller/stullercom/feat/user-agent'

interface MinimumSupported {
  /**
   * Name of the browser
   */
  name: string
  /**
   * Major version of the browser
   */
  majorVersion: number | string
  /**
   * Minor version of the browser
   */
  minorVersion?: number | string | null
  /**
   * Optional message to show when the browser is unsupported
   */
  message?: string | null
}

type MinimumsSupported = MinimumSupported[]

type UseUnsupported = MinimumsSupported[0] | null

/**
 * Hook that returns the unsupported browser/OS minimum given minimums to compare to user agent
 */
function useUnsupported (minimums: MinimumsSupported): UseUnsupported {
  const userAgent = useUserAgent()

  return useMemo(() => {
    if (userAgent != null && minimums.length > 0) {
      const minimum = minimums.find(l => l.name === userAgent?.browser.name)
      if (minimum == null) {
        return null
      }

      const userMajorVersion = userAgent.browser.majorVersion ?? 0
      const userMinorVersion = userAgent.browser.minorVersion ?? 0
      let minimumMajorVersion = +(minimum.majorVersion ?? 0)
      minimumMajorVersion = isNaN(minimumMajorVersion) ? 0 : minimumMajorVersion
      let minimumMinorVersion = +(minimum.minorVersion ?? 0)
      minimumMinorVersion = isNaN(minimumMinorVersion) ? 0 : minimumMinorVersion

      return (userMajorVersion < minimumMajorVersion) ||
        (userMajorVersion === minimumMajorVersion && userMinorVersion < minimumMinorVersion)
        ? minimum
        : null
    }

    return null
  }, [userAgent, minimums])
}

export {
  useUnsupported
}
