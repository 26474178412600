import { type ReactElement, useState } from 'react'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { useToggle } from '@stuller/shared/util/react-hooks'
import { MenuItem } from '../MenuItem'
import { LogoutPopover } from './LogoutPopover'

/**
 * Menu item and dropdown for logout (logged in jeweler showcase)
 */
function MenuLogout (): ReactElement {
  const [open, handleToggle, handleOpenChange] = useToggle()
  const [reference, setReference] = useState<Element | null | undefined>(null)

  return (
    <>
      <MenuItem
        icon={fas.faUser}
        text='Logout'
        open={open}
        onClick={handleToggle}
        setReference={setReference}
      />
      <LogoutPopover
        open={open}
        onOpenChange={handleOpenChange}
        reference={reference}
      />
    </>
  )
}

export {
  MenuLogout
}
