import {
  type ReactNode,
  forwardRef,
  useMemo,
  type MouseEventHandler
} from 'react'
import clsx from 'clsx'
import { Button, type HeadingTag } from '../../..'

export interface ModalHeaderProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of title element
   */
  children?: ReactNode
  /**
   * Children of subtitle element
   */
  subtitle?: ReactNode
  /**
   * Defined title element type
   */
  tag?: 'div' | HeadingTag
  /**
   * Defined subtitle element type
   */
  subtag?: 'div' | HeadingTag
  /**
   * Handle when toggled from within the modal (if sent and `close` not sent, shows close button that calls this)
   */
  onToggle?: MouseEventHandler<HTMLButtonElement> & MouseEventHandler<HTMLAnchorElement>
  /**
   * Override close button or null
   */
  close?: ReactNode
}

/**
 * Modal header component
 */
const ModalHeader = forwardRef<HTMLDivElement, ModalHeaderProps>(({
  className,
  children,
  subtitle,
  tag: Tag = 'h1',
  subtag: Subtag = 'h2',
  onToggle,
  close,
  ...otherAttributes
}, ref) => {
  const classNames = clsx('modal-header', className)
  const closeButton = useMemo(() => {
    if (close != null) {
      return close
    }
    if (onToggle != null) {
      return <Button onClick={onToggle} close />
    }

    return null
  }, [close, onToggle])

  return (
    <div
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      <div>
        {children != null && <Tag className='modal-title'>{children}</Tag>}
        {subtitle != null && <Subtag className='modal-subtitle'>{subtitle}</Subtag>}
      </div>
      {closeButton}
    </div>
  )
})
ModalHeader.displayName = 'ModalHeader'

export {
  ModalHeader
}
