import { type ReactNode, forwardRef } from 'react'
import clsx from 'clsx'

export interface OffcanvasBodyProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of element
   */
  children?: ReactNode
}

/**
 * Offcanvas body component
 */
const OffcanvasBody = forwardRef<HTMLDivElement, OffcanvasBodyProps>(({
  className,
  children,
  ...otherAttributes
}, ref) => {
  const classNames = clsx('offcanvas-body', className)

  return (
    <div
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {children}
    </div>
  )
})
OffcanvasBody.displayName = 'OffcanvasBody'

export {
  OffcanvasBody
}
