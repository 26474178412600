import { forwardRef, useState, useCallback, type ChangeEvent, useEffect } from 'react'
import { useDebounce } from '@stuller/shared/util/react-hooks'
import { type InputProps, Input } from '../input/Input'
import clsx from 'clsx'

export interface PanPadDataProps {
  FirstName: string
  LastName: string
  CardNumber: string
  ExpirationMonth: string
  ExpirationYear: string
  Cvv: string
  Zip: string
  StreetNumber: string
}

export interface PanPadFieldProps extends InputProps {
  usePanPad: boolean | undefined
  savePanPadData: (panPadData: string) => void
  saveNewCardExpiration: (expirationDate: string, expirationMonth: number, expirationYear: number) => void
  saveNewCardNumber: (cardNumber: string) => void
  saveNewCardCvv?: (cvv: string) => void
  saveNewCardZip?: (zip: string) => void
  savePanPadSuccessStatus?: (status: boolean) => void
  className?: string
}

/**
 * Component used to capture and parse data from a PanPad (card reader) device.
 */
const PanPadInput = forwardRef<HTMLInputElement, PanPadFieldProps>(({
  usePanPad,
  savePanPadData,
  saveNewCardExpiration,
  saveNewCardNumber,
  saveNewCardCvv = null,
  saveNewCardZip = null,
  savePanPadSuccessStatus = null,
  className,
  ...otherAttributes
}, ref) => {
  const [panPadData, setPanPadData] = useState<string>('')
  const debouncedPanPadData = useDebounce<string>(panPadData, 500)
  const matchFieldValue = useCallback((value: string): string[] | null => {
    const regex = /^02[A-F0-9]{4}([A-F0-9]{2})[A-F0-9]{12}(?:%[A-Z*]([0-9*]{1,19})\^([^/]+)[/]?([^^]+)?\^([0-9]{2})([0-9]{2})[^?]+\?\*?)?(?:;([0-9*]{1,19})=([0-9]{2})([0-9]{2})(:[0-9*]{3})?[^?]*\?\*?)?(?:1([0-9]+)=)?(?:0([0-9]+)=)?.*03$/i

    return regex.exec(value)
  }, [])

  const classNames = clsx(className)

  /**
   * Extracts the data from the matches array and returns an object with the data.
   */
  const getDataFromMatches = useCallback((matches: string[]): PanPadDataProps => {
    return {
      FirstName: matches[4],
      LastName: matches[3],
      CardNumber: matches[2] ?? matches[7],
      ExpirationMonth: matches[6] ?? matches[9],
      ExpirationYear: matches[5] ?? matches[8],
      Cvv: matches[10],
      Zip: matches[12],
      StreetNumber: matches[11]
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPanPadData])

  /**
   * When the debouncedPanPadData changes, this effect will run and check if the data matches the regex pattern.
   */
  useEffect(() => {
    const matches = matchFieldValue(debouncedPanPadData)
    if (matches != null && matches.length === 13 && matches[0] !== undefined) {
      const panpadData = getDataFromMatches(matches)
      saveNewCardNumber(panpadData.CardNumber)
      saveNewCardExpiration(`${panpadData.ExpirationMonth}/${panpadData.ExpirationYear}`, parseInt(panpadData.ExpirationMonth, 10), parseInt(panpadData.ExpirationYear, 10) + 2000)
      if (savePanPadSuccessStatus !== null) {
        savePanPadSuccessStatus(true)
      }
      if (savePanPadData !== null) {
        savePanPadData(debouncedPanPadData)
      }
      if (saveNewCardZip !== null) {
        saveNewCardZip(panpadData.Zip)
      }
    }
    setPanPadData('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedPanPadData])

  /**
    * When the input is focused, the panpadSuccessStatus will be set to false.
    */
  const handleFocus = useCallback(() => {
    if (savePanPadSuccessStatus !== null) {
      savePanPadSuccessStatus(false)
    }
  }, [savePanPadSuccessStatus])

  /**
   * Handles the input change event and grabs value.
   */
  const handleInputTextChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setPanPadData(event.target.value)
  }, [])

  if (usePanPad === null) {
    return null
  }

  return (
    <Input
      value={panPadData}
      onChange={handleInputTextChange}
      onFocus={handleFocus}
      type='password'
      autoComplete='off'
      ref={ref}
      className={classNames}
      {...otherAttributes}
      data-test='panpad-input'
    />
  )
})

export {
  PanPadInput
}
