import { memo, useMemo, type ReactElement } from 'react'
import {
  ProductMultiItemCarousel,
  type ProductData
} from '@stuller/stullercom/ui'
import {
  useGetProductGroupsByIdsForRecommendationsQuery,
  type GetProductGroupsByIdsForRecommendationsQuery
} from '@stuller/stullercom/data-access/apollo-queries'
import {
  useProductRecommendations
} from './useProductRecommendations'
import {
  trackViewItemList,
  trackSelectItem,
  type ProductOrGroupTracking
} from '@stuller/stullercom/feat/google-tag-manager'
import { type Auth, useAuth } from '@stuller/stullercom/feat/auth'
import type { RecommendationPlacementProps, RecommendationPlacementsResponse } from './types'

export interface ProductRecommendationCarouselProps {
  /**
   * The group id
   */
  groupId?: string
  /**
   * The placements to get recommendations for ( this is an Algonomy concept )
   */
  placements: RecommendationPlacementProps[]
}

interface ProductRecommendationPlacementsProps {
  /**
   * The placements to get recommendations for ( this is an Algonomy concept )
   */
  placements: RecommendationPlacementsResponse[]
  /**
   * Product groups data returned by GQL query
   */
  productGroups: GetProductGroupsByIdsForRecommendationsQuery
}

interface ProductRecommendationPlacementProps {
  /**
   * The placement to get recommendations for ( this is an Algonomy concept )
   */
  placement: RecommendationPlacementsResponse
  /**
   * Product groups data returned by GQL query
   */
  productGroups: GetProductGroupsByIdsForRecommendationsQuery
  /**
   * classname to give the header of the carousel
   */
  headerClassName?: string
}

/**
 * Transform the product data from Algonomy into the format expected by the ProductMultiItemCarousel
 */
function useProductCarouselData (
  auth: Auth,
  placement: RecommendationPlacementsResponse,
  productGroupData: GetProductGroupsByIdsForRecommendationsQuery
): [ProductData[], ProductOrGroupTracking[]] {
  return useMemo(() => {
    const products: ProductData[] = []
    const productsTracking: ProductOrGroupTracking[] = []

    for (const placementProduct of placement.products) {
      const productGroup = productGroupData.productGroupsByIds.find(g => g.id === placementProduct.id)

      if (productGroup != null) {
        const tracking: ProductOrGroupTracking = {
          ...productGroup,
          index: placementProduct.recommendationIndex,
          item_list_id: placement.placement
        }
        products.push({
          id: placementProduct.id,
          title: placementProduct.title,
          image: placementProduct.image,
          url: placementProduct.url,
          clickTrackingURL: placementProduct.clickTrackingURL,
          onClick: () => { trackSelectItem(tracking, auth) }
        })
        productsTracking.push(tracking)
      }
    }

    return [products, productsTracking]
  }, [auth, placement, productGroupData])
}

/**
 * Build a single Placmement component
 */
const Placement = memo(({ placement, productGroups, headerClassName }: ProductRecommendationPlacementProps): ReactElement => {
  const auth = useAuth()
  const [products, productsTracking] = useProductCarouselData(auth, placement, productGroups)

  /**
   * GA4 event `trackViewItemList`
   */
  function handleOnLoad (): void {
    trackViewItemList(productsTracking, auth)
  }

  return (
    <div className='mt-6'>
      <h2 className={headerClassName}>{placement.strategyMessage}</h2>
      <ProductMultiItemCarousel products={products} onLoad={handleOnLoad} />
    </div>
  )
})
Placement.displayName = 'Placement'

/**
 * Contianer for list of Placement components
 */
const Placements = memo(({ placements, productGroups }: ProductRecommendationPlacementsProps): ReactElement => {
  return <>{placements.map((placement) => <Placement key={placement.elementId} placement={placement} productGroups={productGroups} />)}</>
})
Placements.displayName = 'Placements'

/**
 * The product recommendation carousel that shows a list of recommended products from Algonomy
 */
const ProductRecommendationCarousel = ({
  groupId,
  placements
}: ProductRecommendationCarouselProps): ReactElement => {
  const { data } = useProductRecommendations({ groupId, placements })

  let productIds: string[] = []
  if (data != null) {
    for (const placement of data) {
      productIds = productIds.concat(productIds, placement.products.map((product) => product.id))
    }
  }

  const { data: productGroups } = useGetProductGroupsByIdsForRecommendationsQuery({ skip: (data == null && productIds.length === 0), variables: { ids: productIds } })

  return (
    <>
      {data != null && productGroups != null && <Placements placements={data} productGroups={productGroups} />}
    </>
  )
}

ProductRecommendationCarousel.displayName = 'ProductRecommendationCarousel'

export {
  ProductRecommendationCarousel
}
