import { useEventListener } from '@stuller/shared/util/react-hooks'
import { memo, type ReactElement, useEffect } from 'react'

/**
 * Component that removes `target=_blank` from any clicked anchor or window.open() call
 */
function RemoveTargetBlankWhenFramedInner (): null {
  // Use hook to assign global click handler that will remove target=_blank from clicked anchors
  useEventListener('click', (e: Event): void => {
    if (e.target instanceof HTMLElement) {
      const anchor = e.target?.closest('a')
      if (anchor != null && anchor.target === '_blank') {
        anchor.removeAttribute('target')
      }
    }
  })

  // Reassign window.open to a function that always sets target=_self (always open in same tab)
  useEffect(() => {
    const originalWindowOpen = window.open
    window.open = (url?: string | URL, target?: string, features?: string): Window | null => {
      return originalWindowOpen(url, '_self', features)
    }

    return () => {
      window.open = originalWindowOpen
    }
  }, [])

  return null
}

/**
 * Removes `target=_blank` from any anchor clicked within an iframe
 * Use in Next.js `_app.tsx` (or via layout in `_app.tsx`)
 */
const RemoveTargetBlankWhenFramed = memo((): ReactElement | null => {
  // If this is true we are not in an iframe so don't do anything
  if (typeof window === 'undefined' || window.location === window.parent.location) {
    return null
  }

  return <RemoveTargetBlankWhenFramedInner />
})

RemoveTargetBlankWhenFramed.displayName = 'RemoveTargetBlankIfIframed'

export {
  RemoveTargetBlankWhenFramed
}
