import { useMemo, type ReactElement } from 'react'
import { Select } from '../forms/select/Select'

interface UncontrolledPaginationLimitProps {
  /**
   * Page number from pagination
   */
  pageNumber: number
  /**
   * Selected page limit
   */
  selectedPageLimit: number
  /**
   * Callback to update the selected page limit
   */
  onChangeSelectedPageLimit: (pageLimit: number) => void
  /**
   * List of valid page limits
   */
  pageLimits?: number[]
  /**
   * Total number of results
   */
  totalResults: number
}

/**
 * Displays current page range and total results with option to change the page size
 */
function UncontrolledPaginationLimit ({
  pageNumber,
  selectedPageLimit,
  onChangeSelectedPageLimit,
  pageLimits = [10, 25, 50],
  totalResults
}: UncontrolledPaginationLimitProps): ReactElement {
  const { startIndex, endIndex } = useMemo(() => {
    const maxPageNumber = Math.ceil(totalResults / selectedPageLimit)
    const validPageNumber = Math.min(pageNumber, maxPageNumber)

    const startIndex = totalResults > 0
      ? Math.max((validPageNumber - 1) * selectedPageLimit + 1, 1)
      : 0

    const endIndex = totalResults > 0
      ? Math.min(validPageNumber * selectedPageLimit, totalResults)
      : 0

    return { startIndex, endIndex }
  }, [pageNumber, selectedPageLimit, totalResults])

  function handleChangeSelectedPageLimit (event: React.ChangeEvent<HTMLSelectElement>): void {
    const limit = Number(event.target.value)
    if (!isNaN(limit)) {
      onChangeSelectedPageLimit(limit)
    }
  }

  return (
    <div className='align-items-center d-flex'>
      <div className='flex-nowrap'>
        {`Showing ${startIndex}-${endIndex} of ${totalResults} | Items per page `}
      </div>
      <div className='ms-3'>
        <Select
          defaultValue={selectedPageLimit}
          onChange={handleChangeSelectedPageLimit}
          size='sm'
        >
          {pageLimits.map((limit) => (
            <option key={limit} value={limit}>
              {limit}
            </option>
          ))}
        </Select>
      </div>
    </div>
  )
}

export { UncontrolledPaginationLimit }
