import Link from 'next/link'
import { Reference } from './Reference'
import { ContentstackJsonRteChildren } from './ContentStackJsonRteChildren'
import { type ReactElement } from 'react'
import { ContentstackJsonRteTable } from './ContentstackJsonRteTable'
import { ContentstackJsonRteTypography } from './ContentstackJsonRteTypography'
import { isStringEmpty } from '@stuller/shared/util/core'
import variables from '@stuller/stullercom/ui/styles/variables.module.scss'
import { type JsonNode } from './types'

/**
 * Transform the RTE style object to valid React style object
 */
function rteStyleToReactStyle (rteStyle: Record<string, string>): Record<string, string> {
  const style: Record<string, string> = {}
  if (rteStyle != null) {
    for (const key in rteStyle) {
      const newKey = key.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (_, string) => string.toUpperCase())
      style[newKey] = rteStyle[key]
    }
  }

  return style
}

/**
 * Component for translating the JSON from contentstack's rich text editor into JSX elements to be used in react components
 */
function RenderJson ({
  excludeOuterTags = false,
  attrs,
  type,
  children,
  text,
  bold,
  italic,
  underline,
  superscript,
  subscript,
  className,
  inlineCode,
  id,
  uid,
  table = false,
  strikethrough,
  tableStyle,
  'font-family': fontFamily,
  'font-size': fontSize
}: JsonNode): ReactElement | null {
  // if the data coming in doesn't have an element type or text inside of it, it cannot be rendered
  if (type == null && text == null) {
    return null
  }

  const { style: rteStyle, target, url, anchorLink, alt, width, src } = attrs ?? {}

  const style = rteStyleToReactStyle(rteStyle as Record<string, string>)

  if (children != null && attrs != null) {
    const childElements = <ContentstackJsonRteChildren>{children}</ContentstackJsonRteChildren>
    if (excludeOuterTags) {
      return childElements
    }

    if (table) {
      switch (type) {
        case 'table': {
          return <ContentstackJsonRteTable tableStyle={tableStyle}>{childElements}</ContentstackJsonRteTable>
        }
        default: {
          return null
        }
      }
    }

    switch (type) {
      case 'reference': {
        return <Reference attrs={attrs} />
      }
      case 'embed': {
        if (src == null) {
          return null
        }

        return (
          <iframe
            allowFullScreen
            loading='lazy'
            style={{ aspectRatio: '16 / 9', maxWidth: '700px' }}
            className='w-100'
            src={src}
          />
        )
      }
      case 'img': {
        const imgElement = <img src={url} alt={alt} className='img-fluid' width={width} style={style} />
        if (url == null) {
          return null
        }

        return anchorLink != null ? <Link href={anchorLink} target={target}>{imgElement}</Link> : imgElement
      }
      case 'a': {
        if (url == null) {
          return null
        }

        return (
          <Link
            href={url}
            target={target}
            style={style}
          >
            {childElements}
          </Link>
        )
      }
      case 'hr': {
        return <hr />
      }
      case 'blockquote': {
        return <blockquote className='blockquote' style={style}>{childElements}</blockquote>
      }
      case 'code': {
        return (
          <pre>
            <code
              style={style}
              className='white-space-pre-wrap'
            >
              {childElements}
            </code>
          </pre>
        )
      }
      case 'table': {
        return <ContentstackJsonRteTable tableStyle={tableStyle}>{childElements}</ContentstackJsonRteTable>
      }
      case 'th': {
        return <th className='bg-gray-700 text-white'><ContentstackJsonRteChildren excludeOuterTags>{children}</ContentstackJsonRteChildren></th>
      }
      case 'td': {
        return <td><ContentstackJsonRteChildren excludeOuterTags>{children}</ContentstackJsonRteChildren></td>
      }
      default: {
        const Element = type as keyof React.JSX.IntrinsicElements

        return (
          <Element key={uid} style={style} id={id}>
            {childElements}
          </Element>
        )
      }
    }
  }

  return (
    <ContentstackJsonRteTypography
      key={uid}
      id={id}
      strikethrough={strikethrough}
      subscript={subscript}
      superscript={superscript}
      inlineCode={inlineCode}
      italic={italic}
      bold={bold}
      underline={underline}
      style={{
        ...style,
        fontFamily: !isStringEmpty(fontFamily) ? `${fontFamily}, ${variables.fontFamilySansSerif}` : undefined,
        fontSize: !isStringEmpty(fontSize?.toString()) ? `${fontSize}px` : undefined
      }}
      className={className}
      text={text}
    />
  )
}

export {
  RenderJson,
  rteStyleToReactStyle
}
