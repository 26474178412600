import {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
  type Dispatch,
  type SetStateAction
} from 'react'
import fetch from 'cross-fetch'

export type UseLazyFetch<T> = [
  (overrideUrl?: string | null, options?: UseLazyFetchOptions) => Promise<void>,
  {
    /**
     * Indicates the fetch call is currently being called
     */
    loading: boolean
    /**
     * The data returned from the fetch call
     */
    data?: T
    /**
     * The previous data returned (when url or options change)
     */
    previousData?: T
    /**
     * The error (if any) returned from the fetch call
     */
    error?: Error
    /**
     * Updates the options to trigger a new fetch
     */
    setOptions: Dispatch<SetStateAction<UseLazyFetchOptions | undefined>>
  }
]

type UseLazyFetchAction<T> = { type: 'loading' } | { type: 'fetched', payload: T } | { type: 'error', payload: Error }

interface UseLazyFetchOptions extends RequestInit {
  responseType: 'json' | 'text'
}

/**
 * Hook helper to make fetch calls similar to how Apollo query hook (loading, data, error)
 */
function useLazyFetch<T = unknown> (url?: string | null, options?: UseLazyFetchOptions): UseLazyFetch<T> {
  const cancelRequestRef = useRef<boolean>(false)
  const [optionsState, setOptions] = useState(options)
  const [initialState] = useState<(UseLazyFetch<T>)[1]>(() => ({
    loading: false,
    error: undefined,
    data: undefined,
    previousData: undefined,
    setOptions
  }))

  // Fetch state logic
  const fetchReducer = useCallback((currentState: UseLazyFetch<T>[1], action: UseLazyFetchAction<T>): UseLazyFetch<T>[1] => {
    switch (action.type) {
      case 'loading':
        return { ...initialState, loading: true, previousData: currentState.data }
      case 'fetched':
        return { ...initialState, loading: false, data: action.payload, previousData: currentState.data }
      case 'error':
        return { ...initialState, loading: false, error: action.payload, previousData: currentState.data }
    }
  }, [initialState])

  const [state, dispatch] = useReducer(fetchReducer, initialState)

  const fetchData = useCallback(async (overrideUrl?: string | null, options?: UseLazyFetchOptions) => {
    const currentUrl = overrideUrl ?? url
    const currentOptions = options ?? optionsState

    // Do nothing if the url is not given
    if (currentUrl == null) {
      return
    }

    dispatch({ type: 'loading' })

    try {
      const response = await fetch(currentUrl, currentOptions)
      if (!response.ok) {
        throw new Error(response.statusText)
      }

      const data = (await (currentOptions?.responseType === 'text' ? response.text() : response.json())) as T
      if (cancelRequestRef.current) {
        return
      }

      dispatch({ type: 'fetched', payload: data })
    } catch (error) {
      if (cancelRequestRef.current) {
        return
      }

      dispatch({ type: 'error', payload: error as Error })
    }
  }, [url, optionsState])

  useEffect(() => {
    cancelRequestRef.current = false

    // Use the cleanup function for avoiding a possibly state update after the component was unmounted
    return () => {
      cancelRequestRef.current = true
    }
  }, [])

  return [fetchData, state]
}

export {
  useLazyFetch
}
