import {
  type ReactNode,
  forwardRef,
  useMemo,
  type MouseEventHandler
} from 'react'
import clsx from 'clsx'
import { Button, type HeadingTag } from '../../..'

export interface OffcanvasHeaderProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of title element
   */
  children?: ReactNode
  /**
   * Defined title element type
   */
  tag?: 'div' | HeadingTag
  /**
   * Handle when toggled from within the offcanvas (if sent and `close` not sent, shows close button that calls this)
   */
  onToggle?: MouseEventHandler<HTMLButtonElement> & MouseEventHandler<HTMLAnchorElement>
  /**
   * Override close button or null
   */
  close?: ReactNode
}

/**
 * Offcanvas header component
 */
const OffcanvasHeader = forwardRef<HTMLDivElement, OffcanvasHeaderProps>(({
  className,
  children,
  tag: Tag = 'h1',
  onToggle,
  close,
  ...otherAttributes
}, ref) => {
  const classNames = clsx('offcanvas-header', className)
  const closeButton = useMemo(() => {
    if (close != null) {
      return close
    }
    if (onToggle != null) {
      return <Button onClick={onToggle} close />
    }

    return null
  }, [close, onToggle])

  return (
    <div
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {children != null && <Tag className='offcanvas-title'>{children}</Tag>}
      {closeButton}
    </div>
  )
})
OffcanvasHeader.displayName = 'OffcanvasHeader'

export {
  OffcanvasHeader
}
