import { type ReactElement } from 'react'
import { useCurrency } from '@stuller/shared/util/react-hooks'
import { type AddToCartSectionInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'

interface AddToCartPriceProps {
  addToCartSectionInfo: AddToCartSectionInfoFragment
}

function AddToCartPrice ({ addToCartSectionInfo }: AddToCartPriceProps): ReactElement {
  const { price, soldByDescription } = addToCartSectionInfo
  const basePrice = price?.rounded.total.value ?? 0
  const exchangeRate = price?.rounded.total.exchangeRate ?? { code: 'USD', culture: 'en-US' }
  const [currencyBasePrice] = useCurrency(basePrice, exchangeRate)
  const pricePerCarat = addToCartSectionInfo.price?.rounded.caratPrice?.value ?? 0
  const [currencyPricePerCarat] = useCurrency(pricePerCarat, exchangeRate)
  const pricePerUOM = addToCartSectionInfo.price?.roundedPricePerPackUnit?.value ?? 0
  const [currencyPricePerUOM] = useCurrency(pricePerUOM, exchangeRate)
  const percentOffRapaport = addToCartSectionInfo.__typename === 'SerializedProduct' ? addToCartSectionInfo.percentOffRapaport : null

  return (
    <div>
      {basePrice > 0 && (
        <div className='text-end'>
          <div className='fs-1'>{currencyBasePrice}</div>
          {pricePerCarat > 0 && (
            <div className='fw-bold'>{currencyPricePerCarat}/ct</div>)}
          {pricePerUOM > 0 && soldByDescription != null && (
            <div className='fw-bold'>{currencyPricePerUOM}/{soldByDescription}</div>)}
          {percentOffRapaport != null && (
            <div className='fw-bold'>{percentOffRapaport}% Off Rap.</div>)}
        </div>)}
    </div>

  )
}

export { AddToCartPrice }
