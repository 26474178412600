import { type AppContext } from 'next/app'
import { type CustomAppProps } from './types'
import { logger } from '@stuller/stullercom/feat/datadog-logs'

/**
 * Get Apollo data for the page using `getDataFromTree` on the server
 * See: https://www.apollographql.com/docs/react/api/react/ssr/#getdatafromtree
 */
async function getApolloData (appContext: AppContext, appProps: CustomAppProps): Promise<void> {
  const { AppTree } = appContext

  // If any of the queries fail, we'll catch them to avoid a server 500 error
  // Any successful queries will still be cached in the Apollo cache
  // All failed queries will be re-run client-side
  if (typeof window === 'undefined' && AppTree != null) {
    try {
      const { getDataFromTree } = await import('@apollo/client/react/ssr')
      await getDataFromTree(<AppTree {...appProps} />)
    } catch (error) {
      logger.error('Error while running `getDataFromTree`', {}, error)
    }
  }
}

export {
  getApolloData
}
