import { type CSSProperties, forwardRef, type ReactElement, type ReactNode } from 'react'
import clsx from 'clsx'
import { Spinner, type SpinnerProps } from '../../..'

export interface LoadingProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of the loading indicator
   */
  children?: ReactNode
  /**
   * Inline styles to pass to the containing element
   */
  style?: CSSProperties
  /**
   * Indicates the content is loading
   */
  loading?: boolean
  /**
   * Indicates the way to position the spinner in the container
   */
  position?: 'center' | 'left' | 'right' | 'top' | 'top-left'
  /**
   * Indicates the loading div should only take up the space of the content
   * This is useful for things that aren't loading to start with, but then do (like a button)
   */
  inline?: boolean
  /**
   * Additional class name(s) to give to the sub-containing element for the content children
   */
  contentClassName?: string
  /**
   * Additional class name(s) to give to the spinner
   */
  spinnerClassName?: string
  /**
   * Size of the spinner
   */
  size?: SpinnerProps['size']
  /**
   * Color for spinner (use 'inherit' to match current text color)
   */
  color?: SpinnerProps['color']
  /**
   * Boolean to determine if the spinner remove opacity
   */
  skipWhitewash?: boolean
}

export interface LoadingContentProps {
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Children of the loading indicator
   */
  children?: ReactNode
  /**
   * Indicates the content is loading
   */
  loading: boolean
  /**
   * Size of the spinner
   */
  size?: LoadingProps['size']
  /**
   * Boolean to determine if the spinner remove opacity
   */
  skipWhitewash?: boolean
}

export interface LoadingSpinnerProps {
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Indicates the spinner should be absolute positioned and centered
   */
  position: NonNullable<LoadingProps['position']>
  /**
   * Size of the spinner
   */
  size?: LoadingProps['size']
  /**
   * Color for spinner (use 'inherit' to match current text color)
   */
  color?: LoadingProps['color']
}

/**
 * Loading spinner to position the spinner correctly over content
 */
function LoadingSpinner ({
  className,
  position,
  size,
  color
}: LoadingSpinnerProps): ReactElement {
  const classNames = clsx(
    'position-absolute',
    position === 'center'
      ? 'top-50 start-50 translate-middle'
      : [
        'p-1',
        position === 'left' && 'top-50 start-0 translate-middle-y',
        position === 'right' && 'top-50 end-0 translate-middle-y',
        position === 'top' && 'top-0 start-50 translate-middle-x',
        position === 'top-left' && 'top-0 start-0'
      ],
    className
  )

  return (
    <div className={classNames} style={{ zIndex: 3 }}>
      <Spinner className='d-block' size={size} color={color} />
    </div>
  )
}

/**
 * Content on the loading component
 */
function LoadingContent ({
  className,
  children,
  loading,
  size,
  skipWhitewash = false
}: LoadingContentProps): ReactElement {
  const classNames = clsx(
    loading && [
      'pe-none',
      !skipWhitewash && 'opacity-60',
      size != null ? `loading-${size}` : 'loading-md'
    ],
    className
  )

  return (
    <div className={classNames}>
      {children}
    </div>
  )
}

/**
 * Shows a `Spinner` component while content is loading and whitewashes currently loading content.
 */
const Loading = forwardRef<HTMLDivElement, LoadingProps>(({
  className,
  children,
  loading = false,
  position = 'center',
  inline = false,
  contentClassName,
  spinnerClassName,
  size,
  color,
  skipWhitewash,
  ...otherAttributes
}, ref) => {
  const classNames = clsx(
    'loading position-relative',
    loading && !inline && (position === 'center' || position === 'top') && 'w-100',
    inline && 'd-inline-block',
    className
  )

  return (
    <div
      className={classNames}
      ref={ref}
      {...otherAttributes}
    >
      {loading && <LoadingSpinner className={spinnerClassName} size={size} color={color} position={position} />}
      <LoadingContent className={contentClassName} loading={loading} size={size} skipWhitewash={skipWhitewash}>
        {children}
      </LoadingContent>
    </div>
  )
})
Loading.displayName = 'Loading'

export {
  Loading
}
