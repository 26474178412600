import { type ReactElement, useMemo } from 'react'
import { type ShippingCountdownInfoFragment } from '@stuller/stullercom/data-access/apollo-queries'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from '@awesome.me/kit-3dbd93c064/icons'
import { useDurationCountdown } from '@stuller/shared/util/react-hooks'
import { CountdownInfoSectionPreviousOrder } from './CountdownInfoSectionPreviousOrder'
import { CountdownInfoSectionNoOrder } from './CountdownInfoSectionNoOrder'
import dayjs from 'dayjs'
import type plugin from 'dayjs/plugin/utc'

interface ShippingCountdownProps {
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * The shipping countdown object from the query
   */
  countdown: ShippingCountdownInfoFragment
  /**
   * Indicates to use portal ref to attach the modal to the body or not
   */
  portalBody?: boolean
}

interface ShippingCountdownInnerProps {
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * The shipping countdown object from the query
   */
  countdown: ShippingCountdownInfoFragment
  /**
   * The dayjs duration for the countdown
   */
  duration: plugin.Duration
  /**
   * Indicates to use portal ref to attach the modal to the body or not
   */
  portalBody: boolean
}

/**
 * A component to show the shipping countdown
 */
function ShippingCountdown ({ className, countdown, portalBody = false }: ShippingCountdownProps): ReactElement | null {
  const cutoffDate = useMemo(() => dayjs(countdown.cutoffDate), [countdown])
  const duration = useDurationCountdown(cutoffDate, 1000)

  if (countdown.countdownKind === 'SCHEDULED_ORDER' || duration.as('milliseconds') <= 0) {
    return null
  }

  return <ShippingCountdownInner countdown={countdown} duration={duration} className={className} portalBody={portalBody} />
}

/**
 * The inner component for the shipping countdown once data is calculated
 */
function ShippingCountdownInner ({ className, countdown, duration, portalBody }: ShippingCountdownInnerProps): ReactElement {
  return (
    <div className={className}>
      <div className='row'>
        <div className='col-1 me-4 d-flex align-items-center'>
          <FontAwesomeIcon className='fs-2 text-primary' icon={fas.faTruckFast} />
        </div>
        <div className='col'>
          {countdown.countdownKind === 'NO_ORDER' && <CountdownInfoSectionNoOrder duration={duration} portalBody={portalBody} />}
          {countdown.countdownKind === 'PREVIOUS_ORDER' && <CountdownInfoSectionPreviousOrder duration={duration} portalBody={portalBody} />}
        </div>
      </div>
    </div>
  )
}

export {
  ShippingCountdown
}
