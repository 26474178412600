import { useMemo, useRef } from 'react'
import { useRouter } from 'next/router'
import { type ParsedUrlQuery } from 'querystring'
import { type WatchQueryFetchPolicy } from '@apollo/client'
import {
  type CmsOptionsInput,
  useGetCmsContentByIdQuery,
  useGetCmsContentByNameQuery
} from '@stuller/stullercom/data-access/apollo-queries'
import { useCmsContentOptionsContext } from './useCmsContentOptionsContext'
import { updateSplitTestCookie } from './splitTestVersionCookie'

export interface UseGetCmsContentQueryOptions {
  /**
   * Indicates to get the content via server-side call if applicable
   */
  ssr?: boolean
  /**
   * Fetch policy to use for the query
   */
  fetchPolicy?: WatchQueryFetchPolicy
  /**
   * Indicates to skip the query
   */
  skip?: boolean
}

/**
 * CMS loading/content result from a by name or id query
 */
interface UseGetCmsContentResult {
  /**
   * Indicates currently getting content
   */
  loading: boolean
  /**
   * The CMS content (HTML)
   */
  content?: string
}

type GetCmsContentByNameQueryParam = Parameters<typeof useGetCmsContentByNameQuery>[0]
type GetCmsContentByIdQueryParam = Parameters<typeof useGetCmsContentByIdQuery>[0]
interface QueryDetails {
  /**
   * Indicates to query by name vs query by id
   */
  useNameQuery: boolean
  /**
   * The full query options to use for either by name or id
   */
  options: GetCmsContentByNameQueryParam | GetCmsContentByIdQueryParam
}

/**
 * Parses cms-preview-* query parameters into graphql parameters
 */
function getCmsPreviewOptions (query: ParsedUrlQuery): CmsOptionsInput {
  const options: CmsOptionsInput = {}

  // Add 'cms-preview-' query params
  for (const [key, queryValue] of Object.entries(query)) {
    if (queryValue != null) {
      const value = Array.isArray(queryValue) ? queryValue.slice(-1)[0] : queryValue

      if (key.startsWith('cms-preview-schedule-')) { // cms-preview-schedule-777=schedule-1
        if (options.previewSchedules == null) {
          options.previewSchedules = []
        }

        options.previewSchedules.push({
          contentContainerId: key.replace('cms-preview-schedule-', ''),
          scheduledId: value.replace('schedule-', '')
        })
      } else if (key.startsWith('cms-preview-')) { // cms-preview-version-777=ContentBlock-1
        if (options.previewSpecificVersions == null) {
          options.previewSpecificVersions = []
        }

        const valueParts = value.split('-')
        options.previewSpecificVersions.push({
          contentContainerId: key.replace('cms-preview-latest-', '').replace('cms-preview-version-', ''),
          contentId: valueParts.pop() ?? '',
          contentType: valueParts.join('-'),
          useLatestForRelated: key.includes('cms-preview-latest-')
        })
      }
    }
  }

  return options
}

/**
 * Based on input parameters return correct GraphQL query to get CMS content
 */
function useGetCmsContent (
  contentContainerName?: string | null,
  contentContainerId?: string | null,
  contentOptions?: CmsOptionsInput,
  queryOptions?: UseGetCmsContentQueryOptions,
  noRefetch: boolean = false
): UseGetCmsContentResult {
  const contentOptionsContext = useCmsContentOptionsContext()
  const { query } = useRouter()
  const queryDetails: QueryDetails = useMemo(() => {
    const useNameQuery = contentContainerName != null
    const options: CmsOptionsInput = {
      ...getCmsPreviewOptions(query),
      ...contentOptionsContext,
      ...contentOptions
    }

    return {
      useNameQuery,
      options: {
        ...queryOptions,
        variables: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          name: (useNameQuery ? contentContainerName : undefined)!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: (!useNameQuery ? contentContainerId : undefined)!,
          options
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, noRefetch ? [] : [contentContainerName, contentContainerId, query, queryOptions, contentOptionsContext, contentOptions])
  const queryDetailsRef = useRef(queryDetails)
  const queryOptionsCurrent = noRefetch ? queryDetailsRef.current.options : queryDetails.options
  const nameResult = useGetCmsContentByNameQuery({
    ...queryOptionsCurrent as GetCmsContentByNameQueryParam,
    skip: !queryDetails.useNameQuery || queryOptionsCurrent.skip === true
  })
  const idResult = useGetCmsContentByIdQuery({
    ...queryOptionsCurrent as GetCmsContentByIdQueryParam,
    skip: queryDetails.useNameQuery || queryOptionsCurrent.skip === true
  })
  let loading
  let content
  let splitTestVersionsKey

  if (queryDetails.useNameQuery) {
    splitTestVersionsKey = nameResult.data?.cmsContentByName?.splitTestVersionsKey
    loading = nameResult.loading
    content = nameResult.data?.cmsContentByName?.content
  } else {
    splitTestVersionsKey = idResult.data?.cmsContentById?.splitTestVersionsKey
    loading = idResult.loading
    content = idResult.data?.cmsContentById?.content
  }

  if (splitTestVersionsKey != null) {
    updateSplitTestCookie(splitTestVersionsKey)
  }

  return {
    loading,
    content
  }
}

export {
  useGetCmsContent
}
