import { type NextPageContext } from 'next'
import { type ApolloClient } from '@apollo/client'
import {
  GetAuthUserDocument,
  type GetAuthUserQuery
} from '@stuller/stullercom/data-access/apollo-queries'
import { getAuth as getAuthIn, type GetAuth } from '@stuller/stullercom/feat/auth'
import { logger } from '@stuller/stullercom/feat/datadog-logs'

/**
 * Get auth info for the user/host/session
 */
async function getAuth (
  ctx: NextPageContext,
  apolloClient: ApolloClient<unknown>,
  userSessionId: string
): Promise<GetAuth> {
  // Get auth user and info
  let authUserQueryResults = null

  try {
    authUserQueryResults = await apolloClient.query<GetAuthUserQuery>({ query: GetAuthUserDocument })
  } catch (error) {
    logger.error('Error getting auth user', {}, error)
  }

  return getAuthIn(
    authUserQueryResults?.data?.authUser ?? null,
    ctx.req?.headers?.host ?? window.location.host,
    userSessionId
  )
}

export {
  getAuth
}
