import { Loading } from '../../loading/Loading'
import { type ReactElement } from 'react'

/**
 * Custom select loading indicator for when options are loading, we can show our custom loading spinner
 */
function SelectLoadingIndicator (): ReactElement {
  return (
    <Loading loading size='xs' className='w-auto' />
  )
}

export {
  SelectLoadingIndicator
}
