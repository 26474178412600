import {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
  type Dispatch,
  type SetStateAction
} from 'react'
import fetch from 'cross-fetch'

export interface UseFetch<T> {
  /**
   * Indicates the fetch call is currently being called
   */
  loading: boolean
  /**
   * The data returned from the fetch call
   */
  data?: T
  /**
   * The previous data returned (when url or options change)
   */
  previousData?: T
  /**
   * The error (if any) returned from the fetch call
   */
  error?: Error
  /**
   * Updates the options to trigger a new fetch
   */
  setOptions: Dispatch<SetStateAction<UseFetchOptions | undefined>>
}

type UseFetchAction<T> = { type: 'loading' } | { type: 'fetched', payload: T } | { type: 'error', payload: Error }

interface UseFetchOptions extends RequestInit {
  responseType: 'json' | 'text'
}

/**
 * Hook helper to make fetch calls similar to how Apollo query hook (loading, data, error)
 */
function useFetch<T = unknown> (url?: string, options?: UseFetchOptions): UseFetch<T> {
  const cancelRequestRef = useRef<boolean>(false)
  const [optionsState, setOptions] = useState(options)
  const [initialState] = useState<UseFetch<T>>(() => ({
    loading: url != null,
    error: undefined,
    data: undefined,
    previousData: undefined,
    setOptions
  }))

  // Fetch state logic
  const fetchReducer = useCallback((currentState: UseFetch<T>, action: UseFetchAction<T>): UseFetch<T> => {
    switch (action.type) {
      case 'loading':
        return { ...initialState, loading: true, previousData: currentState.data }
      case 'fetched':
        return { ...initialState, loading: false, data: action.payload, previousData: currentState.data }
      case 'error':
        return { ...initialState, loading: false, error: action.payload, previousData: currentState.data }
    }
  }, [initialState])

  const [state, dispatch] = useReducer(fetchReducer, initialState)

  useEffect(() => {
    // Do nothing if the url is not given
    if (url == null) {
      return
    }

    // Get the data with async function
    const fetchData = async (): Promise<void> => {
      dispatch({ type: 'loading' })

      try {
        const response = await fetch(url, optionsState)
        if (!response.ok) {
          throw new Error(response.statusText)
        }

        const data = (await (optionsState?.responseType === 'text' ? response.text() : response.json())) as T
        if (cancelRequestRef.current) {
          return
        }

        dispatch({ type: 'fetched', payload: data })
      } catch (error) {
        if (cancelRequestRef.current) {
          return
        }

        dispatch({ type: 'error', payload: error as Error })
      }
    }

    void fetchData()
  }, [url, optionsState])

  useEffect(() => {
    cancelRequestRef.current = false

    // Use the cleanup function for avoiding a possibly state update after the component was unmounted
    return () => {
      cancelRequestRef.current = true
    }
  }, [])

  return state
}

export {
  useFetch
}
